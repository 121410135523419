import { BadgeOutlined, PersonOutlineOutlined, Schedule, PendingActionsOutlined, LocationOnOutlined, Person4Outlined, PersonSearchOutlined, TagOutlined, Check, CancelOutlined, Padding, Edit, Remove, Delete } from "@mui/icons-material"
import { Box, Chip, Grid, IconButton, Modal, Tooltip, Typography } from "@mui/material"
import { FlexJustifyStart, Text } from "../../assets/styles/styledComponents"
import NewModal from "../../components/ui/NewModal"
import { dateTime } from "../../helper/datetime"
import { useEffect, useState } from "react"
import VisitMatches from "../../features/schedules/components/VisitMatches"
import { Link } from "react-router-dom"
import EditField from "./EditField"
import { VisitData } from "../../features/visits/types"
import DatePickerEditField from "./DatePickerEditField"
import { MenuOption } from "../../components/form/FactorSettings/FactorConstants"
import useFetcher from "../../hooks/useFetcher"
import SearchInput from "../../components/common/SearchInput/SearchInput"
import useVisits, { VisitsHook } from "../../features/visits/useVisits"
import AddCaregiverModal from "../../features/schedules/components/Modals/Matches/AddMatchModal"
import Loader from "../../components/ui/Loader"
import ConfirmationDialog from "../../components/ui/Modals/components/ConfirmationDailog"
import { COAI } from "../../data/constants"
import { strings } from "../../helper/strings"

type DetailsProps = {
    visit: VisitData,
    openDetails: boolean,
    handleCloseDetails: any,
    onVisitUpdate: (updatedVisit: VisitData) => void,
    visitsHook: VisitsHook,
}
type CaregiverOptionType = {
    caregiverId: number,
    name: string,
}

const CalendarDetails = ({ visit, openDetails, handleCloseDetails, onVisitUpdate, visitsHook }: DetailsProps) => {
    const [openMatches, setOpenMatches] = useState(false);
    const [open_DeleteDialog, setOpen_DeleteDialog] = useState<boolean>(false);
    const isSourceCoai = (): boolean => {
        return COAI.includes(visit?.source?.toLowerCase() ?? '');
    }
    const [editDateAndTime, set_editDateAndTime] = useState<boolean>(false);
    const [editClocking, set_editClocking] = useState<boolean>(false);
    const [editCaregiver, set_editCaregiver] = useState<boolean>(false);
    const [addCaregiverModal, set_OpenAddCaregiverModal] = useState<boolean>(false);
    const [visitDate, set_visitDate] = useState<string>(visit?.visitDate || '');
    const [visitStartTime, set_visitStartTime] = useState<string>(visit?.scheduleStartTime || '');
    const [visitEndTime, set_visitEndTime] = useState<string>(visit?.scheduleEndTime || '');
    const [visitClockIn, set_visitClockIn] = useState<string>(visit?.clockIn || '');
    const [visitClockOut, set_visitClockOut] = useState<string>(visit?.clockOut || '');
    const [visitCaregiverId, set_visitCaregiverId] = useState<number | undefined>(visit?.caregiverId || undefined);
    const [visitCaregiverName, set_visitCaregiverName] = useState<string>(visit?.caregiverName || ' - ');
    const [visitPatientName, set_visitPatientName] = useState<string>();
    const [visitPatientAddress, set_visitPatientAddress] = useState<string>()

    visitsHook.onDelete = () => {
        handleCloseDetails()
    }

    useEffect(() => {
        visitsHook.setVisitId(visit?.visitId);
    }, [visit?.visitId])

    useEffect(() => {
        set_visitPatientName(visit.patientName)
        set_visitPatientAddress(visit.patientAddress)
    }, [])

    const updateCaregiver = (cg: CaregiverOptionType) => {
        if (!cg)
            return;

        set_visitCaregiverName(cg.name);
        set_visitCaregiverId(cg.caregiverId);
    }

    const updateLocalState = (updatedVisit: VisitData) => {
        onVisitUpdate(updatedVisit);
        updateList(updatedVisit)
        set_visitStartTime(updatedVisit?.visitDate || '');
        set_visitStartTime(updatedVisit?.scheduleStartTime || '');
        set_visitEndTime(updatedVisit?.scheduleEndTime || '');
        set_visitClockIn(updatedVisit?.clockIn || '');
        set_visitClockOut(updatedVisit?.clockOut || '');
        set_visitCaregiverId(updatedVisit?.caregiverId || undefined);
        set_visitCaregiverName(updatedVisit?.caregiverName || visitCaregiverName);
    };

    // get/save visit date & time
    const saveDateAndTime = () => {
        const updatedVisit: VisitData = {
            ...visit,
            visitDate,
            scheduleStartTime: visitStartTime,
            scheduleEndTime: visitEndTime,
        };
        visitsHook.save(updatedVisit).then((res: any) => {
            if ((res.data?.statusCode) === 200) {
                const responseVisit: VisitData = res.data?.data;
                updateLocalState(responseVisit);
                set_editDateAndTime(false);
            } else
                resetDateAndTime();
        });
        // make sure this component is back to read mode, and uses the updated state from parent component prop
        // make sure same also when editing again same field, other fields, again this field,...
    }

    // get/save visit clocking
    const saveClocking = () => {
        const updatedVisit: VisitData = {
            ...visit,
            clockIn: visitClockIn,
            clockOut: visitClockOut
        };
        visitsHook.save(updatedVisit).then((res: any) => {
            if ((res.data?.statusCode) === 200) {
                const responseVisit: VisitData = res.data?.data;
                updateLocalState(responseVisit);
                set_editClocking(false);
            } else
                resetClocking();
        });
    }

    // save visit caregiver
    const saveCaregiver = () => {
        set_editCaregiver(false)
        const updatedVisit: VisitData = {
            ...visit,
            caregiverId: visitCaregiverId,
            caregiverName: visitCaregiverName,
        };
        visitsHook.save(updatedVisit).then((res: any) => {
            if ((res.data?.statusCode) === 200) {
                const responseVisit: VisitData = res.data?.data;
                updateLocalState(responseVisit);
                set_editCaregiver(false);
            } else
                resetCaregiver();
        });
    }

    const handleOpenCaregiverModel = () => {
        set_editCaregiver(true)
        set_OpenAddCaregiverModal(true)
    }

    const resetDateAndTime = () => {
        set_visitDate(visit.visitDate ?? '');
        set_visitStartTime(visit.scheduleStartTime || '');
        set_visitEndTime(visit.scheduleEndTime || '');
        set_editDateAndTime(false);
    }

    const resetClocking = () => {
        set_visitClockIn(visit.clockIn || '');
        set_visitClockOut(visit.clockOut || '');
        set_editClocking(false);
    }

    const resetCaregiver = () => {
        set_editCaregiver(false)
        set_visitCaregiverId(visit.caregiverId || undefined);
        set_visitCaregiverName(visit.caregiverName || ' - ');
        set_editCaregiver(false);
    }

    const updateList = (updatedVisit: VisitData) => {
        visitsHook.updateRow(updatedVisit, true);
    }

    const titleTxt = !!visit?.visitId ? ('Visit Details - ' + ((!visit?.source ? 'CoAi' : visit.source?.toUpperCase()) ?? 'SRC NA')) : 'New Visit';
    return (
        !!visit ? <>
            <NewModal
                open={openDetails && !openMatches}
                title={visit?.isMissedVisit ?
                    <FlexJustifyStart>
                        {titleTxt}
                        <Chip size="small" label={'Missed Visit'} sx={{ color: 'red', background: 'transparent', border: '1px solid red', borderRadius: '5px', marginLeft: '30px' }} />
                    </FlexJustifyStart>
                    : titleTxt
                }
                onClose={() => handleCloseDetails()} onSave={() => { }}
                hideBtn
                height="350px"
                zIndex={10000000}
            >
                <Box>
                    <Loader isLoading={visitsHook.fetcher.isLoading} />
                    <FlexJustifyStart margin='10px'>
                        <Tooltip title={isSourceCoai() ? 'No HHX ID' : 'HHX Visit ID'}>
                            <TagOutlined
                                fontSize="small"
                                sx={{
                                    marginRight: '15px',
                                    color: isSourceCoai() ? 'lightgray' : 'black'
                                }} />
                        </Tooltip>
                        <Typography>{visit?.hhaVisitId}</Typography>
                    </FlexJustifyStart>
                    <FlexJustifyStart margin='10px'>
                        <Tooltip title='Patient'>
                            <PersonOutlineOutlined fontSize="small" sx={{ marginRight: '15px' }} />
                        </Tooltip>
                        <Typography><Link to={`/patient/${visit?.patientId}/details`} target='_blank' style={{ color: 'black', cursor: 'pointer' }}>{visitPatientName}</Link></Typography>
                    </FlexJustifyStart>
                    <Box

                        onClick={() => {
                            if (!!editClocking || !!editCaregiver) {
                                return;
                            }
                            if (isSourceCoai() && !editDateAndTime) {
                                set_editDateAndTime(true);
                            }
                        }}>
                        <FlexJustifyStart margin='10px'>
                            <Tooltip title='Visit Date & Time'>
                                <Schedule fontSize="small" sx={{ marginRight: '15px' }} />
                            </Tooltip>
                            {!!editDateAndTime ?
                                <Box>
                                    <Grid container spacing={0.4}>
                                        <Grid item xs={4}>
                                            <DatePickerEditField
                                                label={'Date'}
                                                type="date"
                                                value={new Date(visitDate || '')}
                                                onChange={(value) => {
                                                    if (!!value)
                                                        set_visitDate(dateTime.getDateTime(value));
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <DatePickerEditField
                                                label={'Start'}
                                                type="time"
                                                value={new Date(visitStartTime || '')}
                                                onChange={(value) => {
                                                    if (!!value)
                                                        set_visitStartTime(dateTime.getDateTime(value));
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <DatePickerEditField
                                                label={'End'}
                                                type="time"
                                                value={new Date(visitEndTime || '')}
                                                onChange={(value) => {
                                                    if (!!value)
                                                        set_visitEndTime(dateTime.getDateTime(value));
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={0.9} sx={{ height: '18px', marginTop: '-5px' }}>
                                            <IconButton onClick={saveDateAndTime}>
                                                <Check fontSize="small" color="success" />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={0.9} sx={{ height: '18px', marginTop: '-5px' }}>
                                            <IconButton onClick={resetDateAndTime}>
                                                <CancelOutlined fontSize="small" color="error" />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box> :
                                <Typography>{dateTime.getDateWithDay(visitDate)} {dateTime.getTimeStringNew(visitStartTime || '')} - {dateTime.getTimeStringNew(visitEndTime || '')}</Typography>
                            }
                            {isSourceCoai() && !editDateAndTime && (
                                <Box>
                                    <IconButton
                                        disabled={!!editClocking || !!editCaregiver}
                                        onClick={() => set_editDateAndTime(true)} sx={{ marginTop: '-5px' }}>
                                        <Edit color={!!editCaregiver || !!editClocking ? "disabled" : "primary"} fontSize="small" />
                                    </IconButton>
                                </Box>
                            )}
                        </FlexJustifyStart>
                    </Box>
                    <Box

                        onClick={() => {
                            if (!!editDateAndTime || !!editCaregiver) {
                                return;
                            }
                            if (isSourceCoai() && !editClocking) {
                                set_editClocking(true);
                            }
                        }}>
                        <FlexJustifyStart margin='10px'>
                            <Tooltip title='Clock In & Out'>
                                <PendingActionsOutlined fontSize="small" sx={{ marginRight: '15px' }} />
                            </Tooltip>
                            {!!editClocking ?
                                <Box>
                                    <Grid container spacing={0.4}>
                                        <Grid item xs={5}>
                                            <DatePickerEditField
                                                label={'Clock In'}
                                                type="time"
                                                value={new Date(visitClockIn || '')}
                                                onChange={(value) => {
                                                    if (!!value)
                                                        set_visitClockIn(dateTime.getDateTime(value));
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <DatePickerEditField
                                                label={'Clock Out'}
                                                type="time"
                                                value={new Date(visitClockOut || '')}
                                                onChange={(value) => {
                                                    if (!!value)
                                                        set_visitClockOut(dateTime.getDateTime(value));
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={0.9} sx={{ height: '18px', marginTop: '-5px' }}>
                                            <IconButton onClick={saveClocking}>
                                                <Check fontSize="small" color="success" />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={0.9} sx={{ height: '18px', marginTop: '-5px' }}>
                                            <IconButton onClick={resetClocking}>
                                                <CancelOutlined fontSize="small" color="error" />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box> :
                                <Typography>{visitClockIn ? dateTime.getTimeStringNew(visitClockIn) : ''} - {visitClockOut ? dateTime.getTimeStringNew(visitClockOut) : ' - '}</Typography>
                            }
                            {isSourceCoai() && !editClocking && (
                                <Box>
                                    <IconButton
                                        disabled={!!editDateAndTime || !!editCaregiver}
                                        onClick={() => set_editClocking(true)} sx={{ marginTop: '-5px' }}>
                                        <Edit
                                            color={!!editDateAndTime || !!editCaregiver ? "disabled" : "primary"} fontSize="small" />
                                    </IconButton>
                                </Box>
                            )}
                        </FlexJustifyStart>
                    </Box>
                    <FlexJustifyStart margin='10px'>
                        <Tooltip title='Patient Address'>
                            <LocationOnOutlined fontSize="small" sx={{ marginRight: '15px' }} />
                        </Tooltip>
                        <Typography>{visitPatientAddress}</Typography>
                    </FlexJustifyStart>
                    <Box>
                        <FlexJustifyStart margin='10px'>
                            <Grid container spacing={1}>
                                <Grid item xs={1}>
                                    <Tooltip title='Caregiver'>
                                        <Person4Outlined fontSize="small" sx={{ marginRight: '15px' }} />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography><Link to={`/caregiver/${visitCaregiverId}/details` || '-'} target='_blank' style={{ color: 'black', cursor: 'pointer' }}>
                                        {visitCaregiverName || ' - '}
                                    </Link></Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    {dateTime.getDateObject(visitDate || '') >= new Date() && COAI.includes(visit.source?.toLowerCase() ?? '') &&
                                        <Tooltip title={`${!!visitCaregiverId ? 'Change' : 'Select'} Caregiver`}>
                                            <IconButton
                                                disabled={!!editDateAndTime || !!editClocking}
                                                onClick={() => handleOpenCaregiverModel()} sx={{ marginTop: '-5px' }}
                                            >
                                                <Edit color={!!editDateAndTime || !!editClocking ? "disabled" : "primary"} fontSize="small" />

                                            </IconButton>
                                        </Tooltip>
                                    }
                                </Grid>
                                <Grid item xs={1}>
                                    {dateTime.getDateObject(visitDate || '') >= new Date() &&
                                        <Tooltip placement='bottom' title={'Find Caregiver'}>
                                            <IconButton
                                                disabled={!!editDateAndTime || !!editClocking}
                                                onClick={() => setOpenMatches(true)}
                                            >
                                                <PersonSearchOutlined fontSize="small" color={!!editDateAndTime || !!editClocking ? "disabled" : "primary"} sx={{ marginTop: '-5px' }} />
                                            </IconButton>
                                        </Tooltip>}
                                </Grid>
                                {
                                    (!visitCaregiverId && !visit?.caregiverId) == false && // if one is 'null' and one is 'undefined' then consider it not edited. happens when opening an existing visit that doesn't have a CG
                                    visitCaregiverId !== visit?.caregiverId && COAI.includes(visit.source?.toLowerCase() ?? '') &&
                                    <>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={0.9} sx={{ height: '18px', marginTop: '-5px' }}>
                                            <IconButton
                                                disabled={!!editDateAndTime || !!editClocking}
                                                onClick={saveCaregiver}>
                                                <Check fontSize="small" color="success" />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={0.9} sx={{ height: '18px', marginTop: '-5px' }}>
                                            <IconButton
                                                onClick={resetCaregiver}>
                                                <CancelOutlined fontSize="small" color="error" />
                                            </IconButton>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </FlexJustifyStart>
                    </Box>
                    <Box sx={{ float: "right" }}>
                        <IconButton
                            disabled={!visit?.visitId || !COAI.includes(visit.source?.toLowerCase() ?? '')}
                            onClick={() => { COAI.includes(visit.source?.toLowerCase() ?? '') && setOpen_DeleteDialog(true) }}
                        >
                            <Delete fontSize="small" color={!!visit?.visitId && COAI.includes(visit.source?.toLowerCase() ?? '') ? "error" : "disabled"} />
                        </IconButton>
                    </Box>
                </Box>
                <AddCaregiverModal open={addCaregiverModal} handleClose={() => { set_OpenAddCaregiverModal(false) }} onSave={updateCaregiver} />
                <ConfirmationDialog
                    open={open_DeleteDialog}
                    onClose={async (answer) => {
                        if (!COAI.includes(visit.source?.toLowerCase() ?? ''))
                            return;
                        if (!!answer) {
                            await visitsHook.deleteRow();
                        }
                        setOpen_DeleteDialog(false);
                    }}
                    action="Delete"
                    item="Visit"
                />
            </NewModal>
            <Modal sx={{ position: 'absolute', left: '10%', right: '10%', top: '5%' }} onClose={() => setOpenMatches(false)} open={openMatches}>
                <VisitMatches
                    caseBased={false}
                    visit={visit}
                    factorSettingsId={undefined}
                    visitDates={[visitDate, visitDate]}
                />
            </Modal>
        </> : <>No visit data</>
    )
}

export default CalendarDetails