import { Box, Button, Checkbox, Chip, Grid, Hidden, IconButton, TextField, Tooltip, Typography } from "@mui/material"
import { CUSTOM_SCROLL, weekDays } from "../../data/constants"
import { BoldTitle, FlexExactCenter, FlexJustifyStart, Title } from "../../assets/styles/styledComponents"
import useFetcher from "../../hooks/useFetcher"
import { useEffect, useState } from "react"
import { dateTime, getLastDayOfMonth, getShortTimeFormat } from "../../helper/datetime"
import { VisitData } from "../../features/visits/types"
import { Circle, CircleOutlined, NavigateBefore, NavigateNext, Person4Outlined, PersonOutlineOutlined, Repeat, Schedule } from "@mui/icons-material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import Loader from "../../components/ui/Loader"
import CalendarDetails from "./CalendarDetails"
import { PatientProps_Type } from "../../features/patients/type"
import useMultiSelect from "../../hooks/useMultiSelect"
import BadgeNumber from "./BadgeNumber"
import useVisits, { VisitsHook } from "../../features/visits/useVisits"
import { InviteData } from "../../features/invites/types"
import EditInvite from "../../features/schedules/components/Modals/EditInvite.tsx/EditInvite"

type CalendarProps = PatientProps_Type & {
    module: string,
    id: string,
    width?: string,
    dateBoxHeight?: string,
    passedDate?: Date,
    showCaregiver?: boolean,
    showPatient?: boolean,
    visitsList?: VisitData[],
    setVisitsList?: React.Dispatch<React.SetStateAction<VisitData[]>>,
    selectMode?: boolean,
    selectedIds?: string[]
    setSelectedIds?: any;
    setSelectedMonth?: (date: Date) => void,
    visitsHook?: VisitsHook,
}
type WeekdayInfo = {
    number: number;
    full: string;
};

interface VisitCount {
    visitId: string;
    VisitDate: string;
}

const weekdayMap: Record<string, WeekdayInfo> = {
    Sun: { number: 0, full: 'Sunday' },
    Mon: { number: 1, full: 'Monday' },
    Tue: { number: 2, full: 'Tuesday' },
    Wed: { number: 3, full: 'Wednesday' },
    Thu: { number: 4, full: 'Thursday' },
    Fri: { number: 5, full: 'Friday' },
    Sat: { number: 6, full: 'Saturday' },
};

const Calendar = ({ module, id, width = '100%', dateBoxHeight = '100px', passedDate, showCaregiver, showPatient, visitsList: visitsList_prop
    , patientId, patientName, patientAddress, selectMode, selectedIds, setSelectedIds, setSelectedMonth, visitsHook: visitsHook_prop
}: CalendarProps) => {
    const fetcher = useFetcher();
    const [offset, setOffset] = useState(0);
    const [visits, setVisits] = useState<VisitData[]>(visitsList_prop || [])
    const [invites, setInvites] = useState<InviteData[]>([])
    const [boxes, setBoxes] = useState<number[]>([]);
    const [selectedDay, setSelectedDay] = useState(0);
    const [openDetails, setOpenDetails] = useState(false);
    const [selectedInvite, setSelectedInvite] = useState<string>('');
    const [openInvite, setOpenInvite] = useState(false);
    const [selectedVisit, setSelectedVisit] = useState<VisitData | null>(null);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getUTCMonth() + 1);
    const isValid = passedDate instanceof Date && !isNaN(passedDate.getTime());
    const [calendarDate, setCalendarDate] = useState<Date>(!!isValid ? passedDate : new Date());
    const [selectedMonthDate, set_selectedMonthDate] = useState<Date | undefined>(undefined);
    const [lastDayOfSelectedMonth, set_lastDayOfSelectedMonth] = useState<Date | undefined>(undefined);
    const multiSelection = useMultiSelect([])
    const [visitsNextMonth, setVisitsNextMonth] = useState<VisitCount[]>([]);
    const [visitsPrevMonth, setVisitsPrevMonth] = useState<VisitCount[]>([]);
    const [visitsNextMonthFiltered, setVisitsNextMonthFiltered] = useState<VisitCount[]>([]);
    const [visitsPrevMonthFiltered, setVisitsPrevMonthFiltered] = useState<VisitCount[]>([]);

    let visitsHook = useVisits({ setList: setVisits });
    visitsHook = visitsHook_prop || visitsHook;
    visitsHook.addSubscriber((updatedList: VisitData[]) => {
        setVisits(updatedList);
    })

    useEffect(() => {
        if (selectedIds) {
            multiSelection.setSelected(selectedIds)
        }
    }, []);

    const getVisits = () => {
        if (Number.isNaN(year) || Number.isNaN(month)) {
            return;
        }
        fetcher.get(`Visit/${module}/by_month/${id}/${year}-${month}-01`).then(res => {
            setVisits(res.data.visits)
            setInvites(res.data.invites)
            setVisitsPrevMonth(res.data.visitsPrevMonth)
            setVisitsNextMonth(res.data.visitsNextMonth)
        })
    }
    useEffect(() => {
        if (!!visitsList_prop)
            setVisits(visitsList_prop);
    }, [visitsList_prop])

    useEffect(() => {
        if (!!selectMode)
            return;

        const isValid = passedDate instanceof Date && !isNaN(passedDate.getTime());
        if (!!isValid)
            setCalendarDate(passedDate);
    }, [passedDate, selectMode])

    useEffect(() => {
        if (typeof calendarDate.getFullYear !== "function")
            return;
        setYear(calendarDate.getFullYear())
        setMonth(calendarDate.getUTCMonth() + 1)
    }, [calendarDate])

    useEffect(() => {
        if (!visitsList_prop)
            getVisits()
    }, [month, year, id, module])

    useEffect(() => {
        const firstDayOfMonth = new Date(year, month - 1, 1);
        setOffset(firstDayOfMonth.getDay());

        set_selectedMonthDate(firstDayOfMonth);
        const lastDayOfMonth = getLastDayOfMonth(month - 1, year);
        set_lastDayOfSelectedMonth(lastDayOfMonth);
    }, [year, month])

    useEffect(() => {
        if (Number.isNaN(year) || Number.isNaN(month)) {
            return;
        }
        const daysInMonth = getLastDayOfMonth(month - 1, year).getDate();
        const MONTH_BOXES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
        let localboxes = MONTH_BOXES;
        localboxes.length = daysInMonth
        setBoxes(localboxes)
    }, [year, month])

    useEffect(() => {
        if (!!setSelectedIds && typeof setSelectedIds === 'function')
            setSelectedIds(multiSelection.selected)
    }, [multiSelection])

    const isToday = (day: number) => {
        return month == new Date().getUTCMonth() + 1 && day == new Date().getDate()
    }

    const handleMoveMonth = (direction: string) => {
        let newDate = new Date(calendarDate)
        if (direction == 'f')
            newDate.setMonth(new Date(calendarDate).getUTCMonth() + 1)
        if (direction == 'b')
            newDate.setMonth(new Date(calendarDate).getUTCMonth() - 1)

        setCalendarDate(newDate)
    }

    const handleClickVisit = (visit: VisitData) => {
        setSelectedVisit(visit)
        setOpenDetails(true)
    }

    const handleCloseDetails = () => {
        setSelectedVisit(null)
        visitsHook.setVisitId(0)
        setOpenDetails(false)
    }
    const handleClickInvite = (InviteCode?: string) => {
        setSelectedInvite(InviteCode || '');
        setOpenInvite(true);
    };

    const countSelectedIds = (visitCounts: VisitCount[]) => {
        const selectedVisitsInSeq: VisitCount[] = [];
        for (let v of visitCounts) {
            if (multiSelection.hasValue(v.visitId)) {
                selectedVisitsInSeq.push(v);
            }
        }
        return selectedVisitsInSeq;
    };

    useEffect(() => {
        if (!!visitsNextMonth && Array.isArray(visitsNextMonth))
            setVisitsNextMonthFiltered(countSelectedIds(visitsNextMonth));
        if (!!visitsPrevMonth && Array.isArray(visitsPrevMonth))
            setVisitsPrevMonthFiltered(countSelectedIds(visitsPrevMonth));
    }, [visitsPrevMonth, visitsNextMonth, multiSelection.selected])

    return (
        <Box width={width || '100%'}>
            <Loader isLoading={fetcher.isLoading} />
            <FlexExactCenter>
                <IconButton onClick={() => handleMoveMonth('b')} sx={{ marginBottom: '10px' }}>
                    <NavigateBefore fontSize="small" color="primary" />
                    {visitsPrevMonthFiltered.length !== 0 && (
                        <BadgeNumber
                            position="left"
                            count={visitsPrevMonthFiltered.length}
                        />
                    )}
                </IconButton>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label=""
                        value={calendarDate}
                        views={['month', 'year']}
                        onChange={(newValue: Date | null) => {
                            if (newValue === null) return;
                            var newDate = new Date(newValue as Date);
                            setCalendarDate(newDate);
                        }}
                        renderInput={(params: any) => <TextField sx={{
                            width: '200px',
                            mt: 1, mb: 2,
                            '& .MuiOutlinedInput-input': {
                                padding: '4px 10px',
                            }
                        }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...params} helperText={null} />}
                    />
                </LocalizationProvider>
                <IconButton onClick={() => handleMoveMonth('f')}
                    sx={{ marginBottom: '10px', position: 'relative' }} >
                    <NavigateNext fontSize="small" color="primary" />
                    {visitsNextMonthFiltered.length !== 0 && (
                        <BadgeNumber
                            position="right"
                            count={visitsNextMonthFiltered.length}
                        />
                    )}
                </IconButton>
            </FlexExactCenter>
            <Grid marginTop={'0px'} container width={'100%'}>
                {weekDays.map((day: any, index: number) => (
                    <Grid item xs={1.7} key={index}>
                        <Box sx={{ borderRadius: '0px', alignItems: 'center', background: '#7846ff', height: '22px', margin: '1px' }}>
                            <BoldTitle textAlign='center' color='white'>{day}</BoldTitle>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <Grid container width={'100%'}>
                {Array.from({ length: offset }).map((_, index) => (
                    <Grid item xs={1.7} key={index}></Grid>
                ))}
                {boxes.map((day: any, index: number) => {           
                    const boxDate: Date = new Date(year, month - 1, day);
                    const shortWeekday: string = boxDate.toLocaleDateString('en-US', { weekday: 'short' });
                    const weekdayN = weekdayMap[shortWeekday].number;
                    const lastDayOfMonth = lastDayOfSelectedMonth?.getDate() ?? 0;
                    const isLastDayOfMonth = lastDayOfMonth === day;
                    const isBottomLine = lastDayOfMonth - day < 7;
                    const isNotTopLine = day + offset > 7;
                    const visitsSortedByStartTime = visits.filter((visit) => {
                        let newDate = new Date(visit.visitDate ?? '');
                        return newDate.getUTCDate() == day && newDate.getUTCMonth() + 1 == month;
                    }).sort((a, b) => {
                        const startTimeA = new Date(a.scheduleStartTime ?? '').getTime();
                        const startTimeB = new Date(b.scheduleStartTime ?? '').getTime();
                        return startTimeA - startTimeB;
                    });
                    return (
                        <Grid
                            item xs={1.7} key={index} onClick={() => setSelectedDay(day)}
                            sx={{
                                border: '1px solid #e8e0ff',
                                borderRight: (weekdayN + 1) % 7 === 0 || isLastDayOfMonth ? '1px solid #e8e0ff' : '0px',
                                borderBottom: isBottomLine ? '1px solid #e8e0ff' : '0px',
                                borderTop: isNotTopLine ? '1px solid #e8e0ff' : '0px',
                                padding: '0px', minHeight: dateBoxHeight, maxHeight: dateBoxHeight, overflowY: 'scroll',
                                // cursor
                                cursor: 'pointer', // Indicate clickable element
                                outline: 'none', // Remove default outline on focus
                                '&:focus': { // Style on focus
                                    outline: '2px solid #7846ff', // Add custom focus outline
                                },
                                // scrolling
                                ...CUSTOM_SCROLL
                            }}>
                            <Box
                                sx={{ height: '100%' }}
                                onClick={() => {
                                    if (!patientId || !patientName || !patientAddress) {
                                        return; // patient info not available
                                    }

                                    const date_str = dateTime.getDateTime(boxDate);
                                    const midNight = boxDate;
                                    midNight.setHours(0, 0, 0, 0);
                                    const midNight_str = dateTime.getDateTime(midNight);
                                    setSelectedVisit({
                                        patientId,
                                        patientName,
                                        patientAddress,
                                        visitDate: date_str,
                                        scheduleStartTime: midNight_str,
                                        scheduleEndTime: midNight_str,
                                    } as VisitData)
                                    setOpenDetails(true);
                                }}
                            >
                                <Typography
                                    onClick={() => setSelectedDay(day)}
                                    sx={{
                                        background:
                                            isToday(day) ?
                                                //'#7846ff' : selectedDay === day ? 
                                                '#e8e0ff' : 'unset',
                                        textAlign: 'center',
                                        width: '15px',
                                        height: '15px',
                                        fontSize: '12px',
                                        borderRadius: '50%',
                                        border:
                                            isToday(day) ?
                                                //selectedDay === day ? 
                                                '1px solid #7846ff' : '1px solid white',
                                        margin: '0px 0px 0px 0px',
                                        color:
                                            isToday(day) ?
                                                //'white' : selectedDay === day ? 
                                                '#7846ff' : 'unset',
                                        padding: '1px',
                                        lineHeight: '15px', // Or use CSS Grid approach from previous solution
                                    }}
                                >
                                    {day}
                                </Typography>
                                {visitsSortedByStartTime.map((visit: VisitData) => {
                                    let newDate = new Date(visit.visitDate ?? '')
                                    if (newDate.getUTCDate() == day && newDate.getUTCMonth() + 1 == month) {

                                        const TooltipContent = () => (
                                            <Box>
                                                <div>
                                                    {dateTime.getTimeStringNew(visit.scheduleStartTime) + ' - ' + dateTime.getTimeStringNew(visit.scheduleEndTime)}
                                                </div>
                                                <div>
                                                    PT: {visit.patientName || '-'}
                                                </div>
                                                <div>
                                                    CG: {visit.caregiverName || '-'}
                                                </div>
                                            </Box>
                                        );
                                        const iconSize = { width: '10px', height: '10px' };
                                        const personIconSize = { width: '7px', height: '7px' };
                                        const takeFull = (!!showCaregiver && !!showPatient);

                                        // if 'includeToday' is false, then it will only return as future if the current timing is actually after timing of given dateTime
                                        const isDateFuture = (dateT: string | undefined, includeToday: boolean = true): boolean | undefined => {
                                            var dateObj = dateTime.getDateObject(dateT || '');
                                            dateObj.setSeconds(dateObj.getSeconds() + 1)

                                            var startOfToday = new Date();
                                            if (!!includeToday) {
                                                startOfToday.setHours(0);
                                                startOfToday.setMinutes(0);
                                                startOfToday.setSeconds(0);
                                            }

                                            const isTodayOrFuture = dateObj >= startOfToday;

                                            return isTodayOrFuture;
                                        }

                                        return (
                                            <Box>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={!!selectMode ? 10.4 : 12} lg={!!selectMode ? 10.8 : 12} xl={!!selectMode ? 11 : 12}>
                                                        <Chip
                                                            onClick={(event: any) => {
                                                                event.stopPropagation();
                                                                handleClickVisit(visit)
                                                                // Stop event propagation to prevent overriding Box click
                                                            }}
                                                            key={visit.visitId}
                                                            label={
                                                                <>
                                                                    <Tooltip title={<TooltipContent />}>
                                                                        <Grid container spacing={0} alignItems="center">
                                                                            <Grid item xs={takeFull ? 12 : 'auto'}>
                                                                                <Box sx={{ display: 'flex', minWidth: '90px' }}>
                                                                                    {/* <Schedule fontSize="small" sx={{ marginTop: '3px', marginRight: '3px', ...iconSize }} /> */}
                                                                                    <Title color={visit.isMissedVisit ? 'red' : 'unset'} fontSize='11px' sx={{ padding: 0, margin: 0 }}>
                                                                                        {getShortTimeFormat(visit.scheduleStartTime) + ' ' + getShortTimeFormat(visit.scheduleEndTime)}
                                                                                        {visit.schedulePermanency?.toLowerCase() === 'permanent' ?
                                                                                            <Repeat sx={{
                                                                                                height: '11px', width: '12px', fontWeight: 'bold',
                                                                                                color: (
                                                                                                    //!!visit.caregiverId ? 'black' :
                                                                                                    visit.staffingPermanency == 'Temporary') ? 'orange' :  // caregiver on assignment is not assigned to shift
                                                                                                    (visit.staffingPermanency == 'Permanent') ? 'red' : 'black'
                                                                                            }} /> : null}
                                                                                    </Title>
                                                                                </Box>
                                                                            </Grid>
                                                                            {!!showPatient && (
                                                                                <Grid item xs={takeFull ? 12 : 'auto'}>
                                                                                    <FlexJustifyStart sx={{ marginLeft: '0' }}>
                                                                                        {/* <CircleOutlined fontSize="small" sx={{
                                                                                marginRight: '3px',
                                                                                marginLeft: '3px',
                                                                                color: !!visit.patientName ? 'primary' : 'black',
                                                                                ...personIconSize
                                                                            }} /> */}
                                                                                        <Title color={visit.isMissedVisit ? 'red' : 'unset'} fontSize='11px' sx={{ padding: 0, margin: 0 }}>
                                                                                            {visit.patientName || ''}
                                                                                        </Title>
                                                                                    </FlexJustifyStart>
                                                                                </Grid>
                                                                            )}                                                                
                                                                            {!!showCaregiver && (
                                                                                <Grid item xs={takeFull ? 12 : 'auto'}>
                                                                                    <FlexJustifyStart sx={{ marginLeft: '0' }}>
                                                                                        {/* <CircleOutlined fontSize="small" sx={{
                                                                                marginRight: '3px',
                                                                                marginLeft: '3px',
                                                                                color: !!visit.caregiverName ? 'primary' : 'black',
                                                                                ...personIconSize
                                                                            }} /> */}
                                                                                        <Title color={visit.staffingPermanency?.toLowerCase() == 'none' ? 'black' : 'red'} fontSize='11px' sx={{ padding: 0, margin: 0 }}>
                                                                                            {visit.caregiverName || (isDateFuture(visit.visitDate) ? 'Open' : 'NA')}
                                                                                        </Title>
                                                                                    </FlexJustifyStart>
                                                                                </Grid>
                                                                            )}
                                                                        </Grid>
                                                                    </Tooltip>
                                                                </>
                                                            }
                                                            sx={{
                                                                color: visit.isMissedVisit ? 'red' : 'unset',
                                                                width: '100%',
                                                                '& > .MuiChip-label': {
                                                                    padding: '0px 1px', // Adjust padding as needed
                                                                },
                                                                background: visit.isMissedVisit ? 'white' : '#e8e0ff',
                                                                border: visit.isMissedVisit ? '1px solid red' : '0px solid #7846ff',
                                                                borderRadius: '1px',
                                                                padding: '0px 0px',
                                                                marginTop: '1px',
                                                                height: !!showPatient || !!showCaregiver ? 'auto' : 'unset', // Adjust height based on content
                                                                display: 'flex',
                                                                justifyContent: 'flex-start',
                                                            }}
                                                        />{
                                                            !!showPatient
                                                        }
                                                         { !!showPatient && invites.map((invite: InviteData) => {
                                                            let newDate = new Date(invite.visitDate ?? '')                                           
                                                        if (
                                                            newDate.getUTCDate() == day && newDate.getUTCMonth() + 1 == month
                                                        ) {
                                                            return (
                                                                <Chip
                                                                    onClick={(event: any) => {
                                                                        event.stopPropagation();
                                                                        handleClickInvite(invite.inviteCode);
                                                                    }}
                                                                    label={
                                                                        <>
                                                                            <Tooltip title={'Invite'}>
                                                                                <Grid container spacing={0} alignItems="center">
                                                                                    <Grid item xs={takeFull ? 12 : 'auto'}>
                                                                                        <Box sx={{ display: 'flex', minWidth: '90px' }}>
                                                                                            {/* <Schedule fontSize="small" sx={{ marginTop: '3px', marginRight: '3px', ...iconSize }} /> */}
                                                                                            <Title color= 'unset' fontSize='11px' sx={{ padding: 0, margin: 0 }}>
                                                                                                {getShortTimeFormat(invite.scheduleStartTime) + ' ' + getShortTimeFormat(visit.scheduleEndTime)}

                                                                                            </Title>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    {!!showPatient && (
                                                                                        <Grid item xs={takeFull ? 12 : 'auto'}>
                                                                                            <FlexJustifyStart sx={{ marginLeft: '0' }}>
                                                                                                <Title color= 'unset' fontSize='11px' sx={{ padding: 0, margin: 0 }}>
                                                                                                    {invite.patientName || ''}
                                                                                                </Title>
                                                                                            </FlexJustifyStart>
                                                                                        </Grid>
                                                                                    )}
                                                                                    {/* {!!showCaregiver && (
                                                                                        <Grid item xs={takeFull ? 12 : 'auto'}>
                                                                                            <FlexJustifyStart sx={{ marginLeft: '0' }}>  
                                                                                                <Title color={visit.staffingPermanency?.toLowerCase() == 'none' ? 'black' : 'red'} fontSize='11px' sx={{ padding: 0, margin: 0 }}>
                                                                                                    {invite.patientName || (isDateFuture(visit.visitDate) ? 'Open' : 'NA')}
                                                                                                </Title>
                                                                                            </FlexJustifyStart>
                                                                                        </Grid>
                                                                                    )                                                                    
                                                                                    } */}
                                                                                    
                                                                                </Grid>
                                                                            </Tooltip>
                                                                        </>
                                                                    }
                                                                    sx={{
                                                                        color: 'unset',
                                                                        backgroundColor: 'yellow',
                                                                        height: '15px',
                                                                        width: '100%',
                                                                        '& > .MuiChip-label': {
                                                                            padding: '0px 1px', 
                                                                        },
                                                                        borderRadius: '1px',
                                                                        padding: '0px 0px',
                                                                        marginTop: '3px',
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-start',
                                                                    }}
                                                                />
                                                            );   
                                                        }
                                                        return null;
                                                    })}        
                                                    </Grid>
                                                    {!!selectMode &&
                                                        <Grid item xs={1.6} lg={1.2} xl={1}>
                                                            <Box sx={{ marginLeft: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                                                                <Checkbox
                                                                    checked={multiSelection.hasValue(visit.visitId?.toString() ?? '0')}
                                                                    onTouchMove={(e) => {
                                                                        e.stopPropagation();
                                                                    }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                    }}
                                                                    onChange={(e) => {
                                                                        e.stopPropagation();
                                                                        // console.log('before', multiSelection.selected);
                                                                        if (visit.visitId !== undefined) {
                                                                            multiSelection.toggleItem(visit.visitId?.toString());
                                                                        }
                                                                    }}
                                                                    sx={{
                                                                        '& .MuiSvgIcon-root': { fontSize: 16 },
                                                                        marginTop: '1px',
                                                                        padding: 0,
                                                                        paddingTop: '0px'
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Box>
                                        )
                                    }
                                })}
                            </Box>
                        </Grid>
                    )
                })}           
            </Grid> 
                {!!selectedVisit && (
                    <CalendarDetails
                        visit={selectedVisit} 
                        openDetails={openDetails} 
                        handleCloseDetails={handleCloseDetails}
                        onVisitUpdate={(updatedVisit: VisitData) => {
                            setSelectedVisit(updatedVisit);
                        }}
                        visitsHook={visitsHook_prop || visitsHook}
                    />
                    )
                }
            {
                openInvite && (
                    <EditInvite 
                        inviteCode={selectedInvite} 
                        open={openInvite} 
                        setOpen={setOpenInvite} 
                    />
                )
            }
        </Box>
    )
}

export default Calendar