import { Avatar, Box, List, ListItemAvatar, ListItemButton, ListItemText, Paper, TextField } from "@mui/material"
import { BoldTitle, DivContainer, FlexBetween } from "../../assets/styles/styledComponents"
import { AccountCircle, AccountCircleOutlined, EventAvailableOutlined, KeyboardArrowDown, KeyboardArrowUp, PsychologyOutlined, SettingsSuggestOutlined } from "@mui/icons-material"
import { app_purple } from "../../data/constants"
import { useEffect, useState } from "react"
import useFetcher from "../../hooks/useFetcher"
import UpdateAvailability from "./UpdateAvailability"
import NewUpdatePreference from "./NewUpdatePreferences"
import { useLocation, useParams } from "react-router-dom"
import useToast from "../../hooks/useToast"
import WorkAvailability from "../../components/ui/Explorer/Tabs/NewAvailability"
import Preferences from "../../components/ui/Explorer/Tabs/Preferences"
import TagPreferences from "../../features/Preferences/TagPreferences"
import usePreferences from "../../features/Preferences/usePreferences"


const UpdateLink = () => {
    const { id, agencyId } = useParams();
    const caregiverId = id;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const token = queryParams.get('token');
    const [editMode, setEditMode] = useState(false);
    const [info, setInfo] = useState<any>();
    const [stats, setStats] = useState<any>();
    const [selected, setSelected] = useState<string>('');
    const fetcher = useFetcher();
    const [isMoreThen60, setIsMoreThen60] = useState(false);
    const [bundle, setBundle] = useState<any>({});
    const { showError } = useToast();
    const prefsHook = usePreferences({ module: 'caregiver', moduleRefRowId: caregiverId, token });

    const profileOptions = [
        { key: 'general', label: bundle['general'], icon: <AccountCircleOutlined fontSize="small" color="primary" /> },
        { key: 'availability', label: bundle['availability'], icon: <EventAvailableOutlined fontSize="small" color="primary" /> },
        { key: 'preference', label: bundle['preference'], icon: <SettingsSuggestOutlined fontSize="small" color="primary" /> },
        { key: 'ability', label: bundle['abilities'], icon: <PsychologyOutlined fontSize="small" color="primary" /> },
    ]

    const getInfo = () => {
        fetcher.get(`update-availability/caregiver/${caregiverId}/${agencyId}?token=${token}`).then((res: any) => {
            setInfo(res.data)
        }).catch((err: any) => {
            showError("Invalid Link")
        })
    }

    const getBundle = () => {
        fetcher.get(`Bundle/update/${caregiverId}/${agencyId}?token=${token}`).then((res: any) => {
            setBundle(res.data.bundle)
        })
    }

    const getStats = () => {
        fetcher.get(`update-availability/c_stats/${caregiverId}/${agencyId}?token=${token}`).then((res: any) => {
            setStats(res.data)
        })
    }

    useEffect(() => {
        getBundle()
        getInfo()
        getStats()
    }, [])

    const InfoField = ({ title, value, isEditable, ml }: { title: string, value: string, isEditable: boolean, ml?: string }) => {
        return (
            <DivContainer>
                <BoldTitle color='#36454F' fontSize='12px'>{title}</BoldTitle>
                <div style={{ marginLeft: ml, }}>
                    <TextField
                        variant="outlined"
                        disabled={isEditable ? !editMode : true}
                        defaultValue={value}
                        size="small"
                        fullWidth
                        sx={{
                            marginTop: '5px',
                            marginBottom: '5px',
                            "& .MuiInputBase-input": {
                                height: '15px',
                            },
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: 'black',
                            },
                        }}
                    />
                </div>
            </DivContainer>
        )
    }

    return (
        <Box sx={{ minHeight: '100vh', overflowX: 'hidden', backgroundColor: '#7846ff' }} display={'flex'} justifyContent={'center'}>
            {info && <Box width={'95%'} height={'100%'} maxWidth={'800px'} sx={{ overflowX: 'hidden' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Paper
                    elevation={8}
                    sx={{
                        borderRadius: '20px',
                        marginTop: '5%',
                        marginBottom: '5%',
                        paddingTop: '15px',
                        paddingBottom: '15px',
                        width: '100%',
                        height: '100%',
                        minHeight: '90vh'
                    }}
                >
                    {selected == '' &&
                        <div>
                            <div style={{ margin: 'auto', width: '100%', maxWidth: '400px', }}>
                                <div style={{ marginLeft: '10px', marginBottom: '10px', display: 'flex', justifyContent: 'center' }}>
                                    <Avatar
                                        alt='logo'
                                        src={`/${info?.agencyName?.replace(' ', '')}.png`}
                                        sx={{ width: '50px', height: '50px' }}
                                    />
                                    <div style={{ marginLeft: '15px', alignItems: 'start', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                        <BoldTitle color='#7846ff' fontSize='18px'>{info?.agencyName}</BoldTitle>
                                        {/* <BoldTitle color='#7846ff' fontSize='10px'>Powered By ConnectAide&#174;</BoldTitle> */}
                                    </div>
                                </div>
                            </div>
                            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                <div style={{ justifyContent: 'center', display: 'flex' }}>
                                    <AccountCircle sx={{ color: app_purple, width: '60px', height: '60px' }} />
                                </div>
                                <BoldTitle textAlign='center' fontSize='25px' color={app_purple}>{info?.nameReg}</BoldTitle>
                            </div>
                        </div>}
                    <List sx={{ maxWidth: '400px', width: '90%', margin: 'auto' }}>
                        {profileOptions.filter(o => selected === '' || o.key === selected).map((option: any) => (
                            <ListItemButton onClick={() => setSelected(selected == option.key ? '' : option.key)} sx={{ border: `1px solid ${app_purple}`, borderRadius: '5px', margin: '10px auto' }}>
                                <ListItemAvatar>
                                    {option.icon}
                                </ListItemAvatar>
                                <ListItemText
                                    primaryTypographyProps={{ color: app_purple }}
                                    primary={option.label}
                                    secondary={option.key == 'availability' && isMoreThen60 ? <BoldTitle color='red'>Update Required</BoldTitle> : ''}
                                />
                                <ListItemAvatar>
                                    {selected == option.key ? <KeyboardArrowUp color="primary" /> : <KeyboardArrowDown color="primary" />}
                                </ListItemAvatar>
                            </ListItemButton>
                        ))
                        }
                    </List>
                    {selected != '' && <Box padding={'10px'} maxWidth={'400px'} width={'90%'} margin={'auto'}>

                        {/* GENERAL */}
                        {selected == 'general' &&
                            <div>
                                {/* <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '15px' }}>
                                    <IconButton onClick={() => setEditMode(!editMode)} >
                                        {editMode ? <Close fontSize="small" color="primary" /> : <Edit fontSize="small" color="primary" />}
                                    </IconButton>
                                    {editMode && <IconButton onClick={() => setEditMode(!editMode)} >
                                        <Check fontSize="small" color="primary" />
                                    </IconButton>}
                                </div> */}
                                <InfoField title={bundle['name']} value={info?.nameReg} isEditable={true} />
                                <FlexBetween padding='0px'>
                                    <InfoField title={bundle['dateOfBirth']} value={info?.dateOfBirth} isEditable={false} />
                                    <InfoField title={bundle['gender']} value={info?.gender} isEditable={false} ml="5px" />
                                </FlexBetween>
                                <InfoField title={bundle['discipline']} value={info?.discipline} isEditable={false} />
                                <InfoField title={bundle['languages']} value={info?.languages} isEditable={true} />
                                <FlexBetween padding={'0px'}>
                                    <InfoField title={bundle['phone']} value={info?.phone || '-'} isEditable={true} />
                                    <InfoField title={bundle['phone'] + ' 2'} value={''} isEditable={false} ml="5px" />
                                </FlexBetween>
                                <InfoField title={bundle['addressLine'] + ' 1'} value={info?.detailedAddress.addressLine1 || '-'} isEditable={true} />
                                <InfoField title={bundle['addressLine'] + ' 2'} value={info?.detailedAddress.addressLine2 || '-'} isEditable={true} />
                                <FlexBetween padding='0px'>
                                    <InfoField title={bundle['city']} value={info?.detailedAddress.city || '-'} isEditable={true} />
                                    <InfoField title={bundle['state']} value={info?.detailedAddress.state || '-'} isEditable={true} ml="5px" />
                                    <InfoField title={bundle['zip']} value={info?.detailedAddress.zipCode || '-'} isEditable={true} ml="5px" />
                                </FlexBetween>
                            </div>
                        }
                        {selected == 'availability' &&

                            <div>
                                <WorkAvailability view="mobile" />
                            </div>
                        }
                        {selected == 'preference' &&
                            <div>
                                <Preferences moduleProp="caregiver" view="mobile" />
                            </div>
                        }
                        {selected == 'ability' &&
                            <div>
                                {/* <NewUpdatePreference pageType='ability' /> */}
                                <TagPreferences
                                    {...prefsHook}
                                    view={"checkbox"}
                                    module="caregiver"
                                />
                            </div>
                        }

                    </Box>}
                </Paper>
            </Box>}
        </Box>
    )
}

export default UpdateLink