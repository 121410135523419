import { GridColumns } from '@mui/x-data-grid-pro';
import { dateTime } from '../helper/datetime';
import { createElement } from 'react';
import GenderIcon from '../components/common/Icons/GenderIcon';
import { Chip } from '@mui/material';
type ColDefType = GridColumns & any

export const numberSortComparator = (v1: number, v2: number) => v1 - v2;
export const dateSortComparator = (v1: string, v2: string) => {
    // Handle the special case for "Never"
    if (v1.toLowerCase() === 'never') return 1;
    if (v2.toLowerCase() === 'never') return -1;

    // Parse dates and compare
    const date1 = new Date(v1);
    const date2 = new Date(v2);
    return date1.getTime() - date2.getTime();
};

export const PREFERENCE_SUGGESTION_COLS: ColDefType = [
    { field: 'name', headerName: 'Name', mode: 'read' },
    { field: 'currentValue', headerName: 'Value', mode: 'read' },
    { field: 'currentSeverity', headerName: 'Severity', mode: 'read' },
    { field: 'statement', headerName: 'Statement', mode: 'read' },
    { field: 'statementOn', headerName: 'StatementOn', mode: 'read' },
]

export const MATCH_COLUMNS: ColDefType = [
    { field: 'hhaVisitId', headerName: 'HHX Visit Id', },
    { field: 'hhxAdmissionId', headerName: 'HHX Admission Id', },
    // {field: 'patientName', headerName:'Patient', navUrl: "patient/{patientId}/details"},
    { field: 'patientNameReverse', headerName: 'Patient', navUrl: "/patient/{patientId}/details" },
    { field: 'visitDate', headerName: 'Visit Date', renderCell: (params: any) => (dateTime.getDateString(params.row.visitDate)), sortComparator: dateSortComparator },
    { field: 'scheduleStartTime', headerName: 'Start Time', renderCell: (params: any) => (dateTime.getTimeString(params.row.scheduleStartTime)), sortComparator: dateSortComparator },
    { field: 'scheduleEndTime', headerName: 'End Time', renderCell: (params: any) => (dateTime.getTimeString(params.row.scheduleEndTime)), sortComparator: dateSortComparator },
    { field: 'coordinator', headerName: 'Coordinator' },
]

export const MATCH_SUB_COLUMNS: ColDefType = [
    // {field: 'hhaCaregiverId', headerName:'HHA Caregiver Id', },
    { field: 'caregiverCode', headerName: 'HHAX Caregiver Code', flex: 1.1 },
    { field: 'name', headerName: 'Caregiver', flex: 2.2, navUrl: "/caregiver/{caregiverId}/details" },
    //{ field: 'address', headerName: 'Address', flex: 4 },
    // { field: 'percentAvailable', headerName: 'Available', flex: 0.7 },
    // {
    //     field: 'distance', headerName: 'Distance', flex: 0.8, renderCell: (params: any) => {
    //         return (createElement("a", { href: params.row.distanceUrl, target: '_blank' }, params.row.distance))
    //     }
    // },
    {
        field: 'distance',
        headerName: 'Distance',
        flex: 0.8,
        renderCell: (params: any) => {
            const isDisabled = !params.row.distanceUrl;
            const isDriving = !isDisabled && params.row.distanceUrl.includes("&travelmode=driving");

            const trainImage = "train_image.png";
            const driveImage = "car_image.png";
            const googleMapsImage = "google-map-icon.svg";
            const url = isDisabled ? googleMapsImage : (isDriving ? driveImage : trainImage);

            const span = createElement("span", { style: { paddingRight: '3px', minWidth: '41px', display: 'inline-block' } }, params.row.distance);

            const img = createElement("img", {
                src: `${process.env.PUBLIC_URL}/${url}`,
                alt: "Icon",
                //title: isDisabled ? 'Couldn\'t validate address' : 'Google Maps',
                title: params.row.address,
                style: {
                    width: '16px',
                    height: '16px',
                    cursor: isDisabled ? 'default' : 'pointer',
                    opacity: isDisabled ? '40%' : 'none',
                    filter: isDisabled ? 'grayscale(100%)' : 'none',
                    borderRadius: '50%', // to make the background a circle
                    padding: '5px' // padding to see the circle
                },
                onClick: isDisabled ? null : () => window.open(params.row.distanceUrl, '_blank'),
                onMouseOver: isDisabled ? null : (e: any) => { e.target.style.backgroundColor = 'lightgray'; },
                onMouseOut: isDisabled ? null : (e: any) => { e.target.style.backgroundColor = 'transparent'; },
                onMouseDown: isDisabled ? null : (e: any) => { e.target.style.backgroundColor = 'darkgray'; },
                onMouseUp: isDisabled ? null : (e: any) => { e.target.style.backgroundColor = 'lightgray'; }
            });

            return createElement("div", {
                style: {
                    display: 'flex',
                    alignItems: 'center',
                    padding: '3px'
                }
            }, span, img);
        }
    }
    ,
    // { field: 'flags', headerName: 'Flags', flex: 1.4 },
    { field: 'lastWorked', headerName: 'Last Worked', flex: 1, sortComparator: dateSortComparator },
]

export const PATIENT_COLUMNS = [
    {
        field: 'gender', headerName: '', flex: 0.2, renderCell: (params: any) => (
            <GenderIcon gender={params.row.gender} />
        )
    },
    { field: "name", headerName: "Name", flex: 0.9, navUrl: "patient/{patientId}/details" },
    { field: "coordinator", headerName: "Coordinator", flex: 1 },
    { field: "hhaPatientId", headerName: "HHAX Patient ID", flex: 0.6 },
    { field: "admissionId", headerName: "HHAX Admission ID", flex: 0.6 },
    { field: "address", headerName: "Address", minWidth: 350, hide: true },
    { field: "phone", headerName: "Phone", flex: 0.7, hide: true },
]

export const EXPLORER_VISITS_COL: (module: string) => ColDefType = (module: string) => {
    switch (module) {
        case 'patient':
        case 'patients':
            return [
                { field: 'hhaVisitId', headerName: 'HHAX Visit Id', },
                { field: 'caregiverName', headerName: 'Caregiver', navUrl: "/caregiver/{caregiverId}/details" },
                { field: 'caregiverAddress', headerName: 'Caregiver Address', minWidth: 300 },
                { field: 'hhaCaregiverId', headerName: 'HHAX Caregiver Id', },
                { field: 'visitDate', headerName: 'Visit Date', type: 'date' },
                { field: 'scheduleStartTime', headerName: 'Start Time', type: 'dateTime' },
                { field: 'scheduleEndTime', headerName: 'End Time', type: 'dateTime' },
            ]
        case 'caregiver':
        case 'caregivers':
            return [
                { field: 'hhaVisitId', headerName: 'HHAX Visit Id', },
                { field: 'patientName', headerName: 'Patient', navUrl: "/patient/{patientId}/details" },
                { field: 'patientAddress', headerName: 'Patient Address', minWidth: 300 },
                { field: 'hhaPatientId', headerName: 'HHAX Patient Id', },
                { field: 'visitDate', headerName: 'Visit Date', type: 'date' },
                { field: 'scheduleStartTime', headerName: 'Start Time', type: 'dateTime' },
                { field: 'scheduleEndTime', headerName: 'End Time', type: 'dateTime' },
            ]
        default:
            return []
    }
}

export const CAREGIVER_COLUMNS: ColDefType = [
    {
        field: 'gender', headerName: '', flex: 0.2, renderCell: (params: any) => (
            <GenderIcon gender={params.row.gender} />
        )
    },
    { field: "caregiverId", headerName: "ID", flex: 0.4, hide: true },
    { field: "name", headerName: "Name", flex: 1, navUrl: "caregiver/{caregiverId}/details" },
    { field: "address", headerName: "Address", flex: 1.6, hide: true },
    { field: "phone", headerName: "Phone", flex: 0.7, hide: true },
    { field: "caregiverCode", headerName: "Caregiver Code", flex: 0.6, sortComparator: numberSortComparator },
    { field: "hhaCaregiverId", headerName: "HHX CG ID", flex: 0.6 },
    {
        field: 'lastWorked', headerName: 'Last Worked', flex: 0.6,
        sortComparator: dateSortComparator,
        renderCell: (params: any) => (
            <div style={{
                color: params.row.lastWorkedDaysAgo < 0 || params.row.lastWorkedDaysAgo > 90 ? '#cc0000' : params.row.lastWorkedDaysAgo < 31 ? '#54943a' : 'black' //'#ff8800'
            }}>
                {params.row.lastWorked}
            </div>
        )
    },
    //{ field: 'lastWorked', headerName: 'Last Worked', flex: 0.6, sortComparator: dateSortComparator },
]

export const VISIT_MODAL: ColDefType = [
    { field: 'hhaCaregiverId', headerName: 'HHAX Caregiver Id', },
    { field: 'name', headerName: 'Caregiver', navUrl: "caregiver/{caregiverId}/details" },
    { field: 'address', headerName: 'Address', minWidth: 300 },
    { field: 'percentAvailable', headerName: 'Available' },
]

export const CAREGIVER_VISITS_MODAL: ColDefType = [
    { field: 'hhaVisitId', headerName: 'HHAX Visit Id' },
    { field: 'patientName', headerName: 'Patient Name', navUrl: "patient/{patientId}/details" },
    { field: 'visitDate', headerName: 'Visit Date' },
    { field: 'scheduleStartTime', headerName: 'Start Time' },
    { field: 'scheduleEndTime', headerName: 'End Time' },
    { field: 'percentAvailable', headerName: 'Available' },
]

export const COORDINATOR_COLS: ColDefType = [
    { field: 'name', headerName: 'Name' },
    { field: 'email', headerName: 'Email' },
    { field: 'hhaCoordinatorId', headerName: 'HHAX Id' },
    { field: 'userId', headerName: 'User Id' },
    { field: 'supervisorName', headerName: 'Supervisor' },
    { field: 'smsNumber', headerName: 'SMS Number' },
]

export const DECLINED_COLS: ColDefType = [
    { field: 'name', headerName: 'Name', navUrl: '/{type}/{declinedId}/details' },
    { field: 'code', headerName: 'HHAX Code' },
    { field: 'reason', headerName: 'Reason' },
    { field: 'formattedDate', headerName: 'Date' },
]

export const PERSONNEL_COLS: ColDefType = [
    { field: 'name', headerName: 'Name', navUrl: '{typeLower}/{id}/details' },
    { field: 'hhaId', headerName: 'HHA ID' },
    { field: 'code', headerName: 'HHA Code' },
];

export const SETTINGS_COLS: ColDefType = [
    { field: 'name', headerName: 'Name' },
    { field: 'description', headerName: 'Description' },
]

export const datagridStyle = (theme: any = {}, props: any = {}) => {
    return {
        root: {
            height: props?.options?.height ?? '85vh',
            width: '100%',
            '& .hidden-header': {
                opacity: 0,
            },
            '& .editable:hover': {
                border: '1px solid black',
            },
            '&.MuiDataGrid-root .MuiDataGrid-row.Mui-hovered': {
                background: "red",
            },
            '& .MuiDataGrid-iconButtonContainer': {
                display: props?.hideHeaderColumnsSortIcon ? "none" : "unset",
            },
            '& .MuiDataGrid-menuIconButton': {
                padding: "0px",
            },
            '&.MuiDataGrid-root .MuiDataGrid-row:hovered': {
                background: "red", //theme.palette.background.paper,
            },
            '&.MuiDataGridPro-columnHeader': {
                background: '#000 !important'
            },
            '& .MuiDataGrid-columnHeaders': {
                background: props?.options?.headerBGColor ?? 'rgb(232,224,255)',
                color: props?.options?.headerTextColor ?? "#000",
            },
            '& .icon-no-padding': {
                padding: '0px !important'
            },
            '& .style-url--cell': {
                '&:hover': {
                    textDecoration: 'underline',
                    color: theme?.palette?.primary?.main,
                    cursor: 'pointer'
                }
            },
            background: "#FFF",
            '& .MuiDataGrid-root': {
                border: 0
            },
            '& .MuiDataGrid-root .MuiDataGrid-footer .MuiDataGrid-selectedRowCount': {
                visibility: 'hidden',
            },
            '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                outline: 'none',
            },
            '&.icon-no-padding': {
                padding: '0px !important'
            },
            // '& .MuiDataGrid-iconSeparator': {
            //     display: 'none !important'
            // }
        },
        hideToolbarText: {
            fontSize: '0px !important',
            minWidth: 'unset !important'
        },
        hideIconSeperator: {

        }
    }
}

/**
 * Over here we define all column defination and we extend to add extra columns that need actions and handler we put those columns inside component.
 */