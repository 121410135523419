const GenderIcon = ({ gender }: { gender: string }) => {
    const src = `${process.env.PUBLIC_URL}/${gender.toLowerCase()}.svg`;
    const filterColor = gender.toLowerCase() === 'female' ? '#e90303' : '#2a5bc7';

    const getFilter = (color: string) => {
        if (color === '#e90303') {
            return 'invert(14%) sepia(98%) saturate(7483%) hue-rotate(355deg) brightness(97%) contrast(113%)';
        } else if (color === '#2a5bc7') {
            return 'invert(28%) sepia(94%) saturate(3183%) hue-rotate(201deg) brightness(92%) contrast(97%)';
        } else {
            return '';
        }
    };

    return (
        <img
            src={src}
            alt={gender}
            style={{
                width: 20,
                height: 17,
                filter: getFilter(filterColor),
            }}
        />
    );
};

export default GenderIcon;