import React from 'react';
import Box from '@mui/material/Box';

interface VersionNumberProps {
  position: 'top-right' | 'top-left' | 'center-top' | 'center-bottom' | 'bottom-left' | 'bottom-right';
  version: string;
  color?: string;
  backgroundColor?: string;
  fontSize?: string | number;
}

const getPositionStyle = (position: string) => {
  switch (position) {
    case 'top-right':
      return { top: 0, right: 0, transform: 'translate(0, 0)' };
    case 'top-left':
      return { top: 0, left: 0, transform: 'translate(0, 0)' };
    case 'center-top':
      return { top: 0, left: '50%', transform: 'translate(-50%, 0)' };
    case 'center-bottom':
      return { bottom: 0, left: '50%', transform: 'translate(-50%, 0)' };
    case 'bottom-left':
      return { bottom: 0, left: 0, transform: 'translate(0, 0)' };
    case 'bottom-right':
      return { bottom: 0, right: 0, transform: 'translate(0, 0)' };
    default:
      return {};
  }
};

const VersionNumber: React.FC<VersionNumberProps> = ({ position, version, color = '#fff', backgroundColor = '#000', fontSize = '12px' }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        padding: '4px 8px',
        color,
        backgroundColor,
        fontSize,
        borderRadius: '4px',
        ...getPositionStyle(position),
      }}
    >
      V: {version}
    </Box>
  );
};

export default VersionNumber;