import React from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import { ApexOptions } from "apexcharts";

interface OfficeDashboardBarChartProps {
    title: string;
    value: string | number;
    secondaryTextBottom?: string;
    chartData: ApexAxisChartSeries;
    chartOptions: ApexOptions; 
    onButtonClick?: () => void; // Optional prop for handling button click
}

const OfficeDashboardBarChart: React.FC<OfficeDashboardBarChartProps> = ({
    title,
    value,
    secondaryTextBottom,
    chartData,
    chartOptions,
    onButtonClick, // Destructure the button click handler
}) => {

    const handleClick = () => {
        if (onButtonClick != null)
        onButtonClick();
    }

    return (
        <Paper elevation={4} sx={{ borderRadius: '10px', backgroundColor: 'white', padding: '10px', height: 'px' }}>
            <Box>
                <Typography variant="h4" component="h4" sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                    {title}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ fontSize: '140px', fontWeight: 'bold' }}>
                    {value}
                </Typography>
            </Box>
            {secondaryTextBottom && (
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                    <Typography variant="body2" sx={{ fontSize: '16px' }}>
                        {secondaryTextBottom}
                    </Typography>
                </Box>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClick} 
                >
                    Click Me
                </Button>
            </Box>
        </Paper>
    );
};

export default OfficeDashboardBarChart;
