import React, { useState } from 'react';
import { Select, MenuItem, Chip, Box, SelectProps, Grid } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

interface Option {
    value: any;
    name: string;
    color?: string;
}

interface ChipSelectProps extends Omit<SelectProps, 'onChange'> {
    options: Option[];
    chipLabel?: (selectedValue: any) => string;
    onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const ChipSelect: React.FC<ChipSelectProps> = ({ options, chipLabel, ...selectProps }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = (e: any) => {
        e.stopPropagation();
        setOpen(true)
    };
    const handleClose = () => setOpen(false);

    const selectedOption = options.find(option => option.value === selectProps.value);
    const chipDisplayLabel = chipLabel ? chipLabel(selectProps.value) : selectedOption?.name;

    return (
        <Box>
            {!open ? (
                <Chip
                    label={
                        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                            <Box minWidth="80px" display="flex" justifyContent="flex-start">
                                {chipDisplayLabel || ''}
                            </Box>
                            <KeyboardArrowDown sx={{ ml: 0.5 }} />
                        </Box>
                    }
                    onClick={handleOpen}
                    size="small"
                    sx={{
                        cursor: 'pointer',
                        height: '30px',
                        width: '120px',
                        fontSize: '0.8rem',
                        backgroundColor: selectedOption?.color || null, // Set background color for the Chip
                    }}
                />
            ) : (
                <Select
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    {...selectProps}
                    fullWidth={selectProps.fullWidth ?? true}
                    size={selectProps.size ?? 'small'}
                    margin={selectProps.margin ?? 'dense'}
                    sx={{ ...selectProps.sx, height: '30px' }}
                    onChange={(e: any) => {
                        selectProps.onChange?.(e);
                        handleClose();
                    }}
                >
                    <MenuItem value={'Select'}><i>SELECT</i></MenuItem>
                    {options.map(option => (
                        <MenuItem
                            key={option.value} value={option.value}
                            sx={{ backgroundColor: option.color || null }} // Set background color
                        >
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            )}
        </Box>
    );
};

export default ChipSelect;