import { Box, Fab, Tooltip } from "@mui/material"
import { GeolocateControl, Layer, LayerProps, Map, MapRef, Popup, Source } from "react-map-gl"
import { MAPBOX_TOKEN } from "../../../data/constants"
import ControlPanel from "../../../pages/Map/ControlPanel"
import { useCallback, useEffect, useRef, useState } from "react"
import { ListOutlined } from "@mui/icons-material"
import Loader from "../../../components/ui/Loader"
import DirectionsBar from "../../../pages/Map/DirectionsBar"
//import MapboxDirections from "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions"
import { SearchBox } from "@mapbox/search-js-react"
import PopupInfo from "../../../pages/Map/PopupInfo"

type MapProps = {
    height?: string,
    geoControl?: boolean,
    latitude?: number,
    longitude?: number,
    setView: any,
    view: any,
    data: LocationType[],
    children?: any
    isLoading: any,
    searchBox?: boolean,
    MapPopUp: any,
    allowOverlay?: boolean,
    setRow?: any,
    setShowCaregiverSchedule?: any
    handleOpenDetails?: any
    handleOpenInvite?: any
    setShowCaregiverPreference?: any
    setShowCaregiverMatch?: any
}

type LocationType = {
    id: any,
    type: any,
    longitude: any,
    latitude: any,
    address: any,
    info: any
}

const MapComponent = ({
    height,
    geoControl,
    latitude,
    longitude,
    setView,
    view,
    data,
    children,
    isLoading,
    searchBox,
    MapPopUp,
    setRow,
    setShowCaregiverSchedule,
    setShowCaregiverMatch,
    setShowCaregiverPreference,
    handleOpenInvite,
    handleOpenDetails,
    allowOverlay = false
}: MapProps) => {
    const mapRef = useRef<MapRef | null>(null);
    const zoom = mapRef.current?.getZoom();
    const [points, setPoints] = useState<any>()
    const [selectedPoint, setSelectedPoint] = useState<any>()
    const [LocationA, setLocationA] = useState<string[]>(['', ''])
    const [LocationB, setLocationB] = useState<string[]>(['', ''])
    const [mapDirections, setMapDirections] = useState<any>(null)
    const [LocationAddressA, setLocationAddressA] = useState<any>(null)
    const [LocationAddressB, setLocationAddressB] = useState<any>(null)
    const [zoomPosition, setZoomPosition] = useState<any>(zoom);
    const [viewState, setViewState] = useState({
        latitude: latitude || 40.690158,
        longitude: longitude || -73.982498,
        zoom: 12,
        bearing: 0,
        pitch: 0,
        padding: { top: 100, bottom: 100, right: 100, left: 100 },
        width: 1000,
        height: 800,
    })

    const handleDrag = (event: any) => {
        const info = event.viewState;

        setViewState((prevViewport) => ({
            ...prevViewport,
            longitude: info.longitude,
            latitude: info.latitude,
            zoom: info.zoom,
            pitch: info.pitch,
            padding: { top: 0, bottom: 0, right: 0, left: 0 }
        }))
    }

    useEffect(() => {
        let features = {
            type: "FeatureCollection",
            features: data.map((feature: any) => ({
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: feature?.address.latitude && feature?.address.longitude ? [feature?.address.longitude, feature?.address.latitude] : [feature?.longitude, feature?.latitude],
                },
                properties: {
                    title: feature.id,
                    type: feature.type
                },
            })),
        }
        setPoints(features)
    }, [data])

    useEffect(() => {
        setZoomPosition(zoom)
    }, [zoom])

    const mapRefCallback = useCallback((ref: MapRef | null) => {
        if (ref !== null) {
            mapRef.current = ref;
            const map = ref;

            const loadIcon = () => {
                if (!map.hasImage("LocationIcon")) {
                    let img = new Image(24, 24);
                    img.crossOrigin = "Anonymous";
                    img.onload = () => {
                        map.addImage("LocationIcon", img, { sdf: true });
                    };
                    img.src = 'caregiver.svg';
                }
            };

            loadIcon();

            map.on("styleimagemissing", (e) => {
                loadIcon();
            });
        }
    }, []);

    const handleLocationClick = (e: any) => {
        if (e.originalEvent.ctrlKey)
            handleDirectionsClick(e)
        else
            handleClick(e)
    }

    const handleClick = (e: any) => {
        let location = data?.find((l: any) =>
            l.id == e?.features?.at(0)?.properties?.title &&
            l.type == e?.features?.at(0)?.properties?.type
        )
        if (location == null)
            return;

        setSelectedPoint(location)

        // setPopupInfo(location)
    }


    const handleDirectionsClick = (e: any) => {
        const { lng, lat } = e.lngLat;

        let location = data?.find((l: any) =>
            l.id == e?.features?.properties?.title &&
            l.type == e?.features?.properties?.type
        )

        if (LocationA[0] == '' && LocationB[0] == '') {
            setLocationA([lng, lat])

            if (location != null)
                setLocationAddressA(location.address)
            else
                setLocationAddressA(null)
        }
        else if (LocationA[0] != '' && LocationB[0] != '') {
            setLocationA([lng, lat])
            if (location != null)
                setLocationAddressA(location.address)
            else
                setLocationAddressA(null)

            setLocationB(['', ''])
            setLocationAddressB(null)
        }
        else if (LocationA[0] != '' && LocationB[0] == '') {
            setLocationB([lng, lat])

            if (location != null)
                setLocationAddressB(location.address)
            else
                setLocationAddressB(null)
        }
    }

    useEffect(() => {
        if (LocationA[0] != '' && LocationB[0] != '') {
            // const directions = new MapboxDirections({
            //     accessToken: MAPBOX_TOKEN,
            //     unit: 'metric',
            //     profile: 'mapbox/driving',
            //     controls: {
            //         inputs: false,
            //         instructions: false,
            //     },
            //     interactive: false,
            // });

            // directions.setOrigin([LocationA[0], LocationA[1]]);
            // directions.setDestination([LocationB[0], LocationB[1]]);

            // mapRef.current?.addControl(directions, 'top-left');
        }
    }, [LocationA, LocationB])

    const closeDirections = () => {
        setMapDirections(null)
        setLocationA(['', ''])
        setLocationB(['', ''])
        setLocationAddressA(null)
        setLocationAddressB(null)
    }

    const handleRetrieve = (res: any) => {
        const coordinates = res.features[0].geometry.coordinates

        setViewState((prevViewport) => ({
            ...prevViewport,
            longitude: coordinates[0],
            latitude: coordinates[1],
            zoom: 12,
            padding: { top: 20, bottom: 20, right: 20, left: 20 }
        }))

        handleSearchLocation(coordinates)
    }

    const handleSearchLocation = (coordinates: any) => {
        const newFeature = {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [coordinates[0], coordinates[1]],
            },
            properties: {
                title: -1,
                type: 'custom'
            },
        };

        const filteredFeatures = points.features
            .filter((feature: any) => feature.properties.title !== -1 || feature.properties.type !== 'custom');

        const updatedFeatures = filteredFeatures.concat(newFeature);

        const updatedData = {
            type: "FeatureCollection",
            features: updatedFeatures,
        };

        setPoints(updatedData);
    }

    const symbolLayer: LayerProps = {
        id: 'anything',
        type: 'symbol',
        source: 'points',
        layout: {
            "icon-image": 'LocationIcon',
            "icon-size": [
                'match',
                ['get', 'type'],
                'custom', 1.5,
                1
            ],
            "icon-allow-overlap": (allowOverlay ? true : zoomPosition >= 11 ? true : false),
        },
        paint: {
            "icon-color": [
                'match',
                ['get', 'type'],
                'Caregiver', '#c4040e',
                'Patient', '#3a02f2',
                'Nurse', '#068f0d',
                'custom', "#FF2400",
                '#000'
            ],
        }
    }

    return (
        <Box>
            <Loader isLoading={isLoading} />
            <Map
                {...viewState}
                trackResize={true}
                ref={mapRefCallback}
                onDrag={(e) => handleDrag(e)}
                onZoom={(e) => handleDrag(e)}
                mapboxAccessToken={MAPBOX_TOKEN}
                onClick={(e,) => handleLocationClick(e)}
                interactiveLayerIds={[symbolLayer.id!]}
                mapStyle="mapbox://styles/mapbox/streets-v12?optimize=true"
                style={{ height: height || '75vh', width: '100%', justifyContent: 'center' }}
            >

                {/* DATA */}
                <Source
                    id="points"
                    type="geojson"
                    data={points}
                />

                {/* SEARCH BAR */}
                {searchBox && <div style={{ zIndex: 100, width: '300px', position: 'absolute', top: '12px', left: '10px' }}>
                    <SearchBox placeholder="Enter Address" value="" onRetrieve={(res: any) => handleRetrieve(res)} accessToken={MAPBOX_TOKEN} />
                </div>}

                {/* MARKERS */}
                <Layer {...symbolLayer} />

                {/* GEOCONTROL */}
                {geoControl && <GeolocateControl position="bottom-right" />}

                {/* CHANGE VIEW */}
                <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                    <Tooltip title='List View'>
                        <Fab size="small" sx={{ background: '#fff', }} onClick={() => setView(!view)}>
                            <ListOutlined color="primary" />
                        </Fab>
                    </Tooltip>
                </div>

                {/* DIRECTIONS */}
                <DirectionsBar
                    LocationA={LocationA}
                    LocationB={LocationB}
                    LocationAddressA={LocationAddressA}
                    LocationAddressB={LocationAddressB}
                    closeDirections={closeDirections}
                />

                {selectedPoint && <MapPopUp
                    info={selectedPoint}
                    setInfo={setSelectedPoint}
                    setRow={setRow}
                    setShowCaregiverSchedule={setShowCaregiverSchedule}
                    setShowCaregiverMatch={setShowCaregiverMatch}
                    setShowCaregiverPreference={setShowCaregiverPreference}
                    handleOpenInvite={handleOpenInvite}
                    handleOpenDetails={handleOpenDetails}
                />}

                {
                    selectedPoint && !MapPopUp && (
                        <PopupInfo
                            popupInfo={selectedPoint.info}
                            setLocations={setPoints}
                            setPopupInfo={setSelectedPoint}
                            popUpAdditions={<MapPopUp info={selectedPoint} setInfo={setSelectedPoint} />}
                        />
                    )
                }

                {/* ADDITIONAL COMPONENTS */}
                {children}

            </Map>
        </Box >
    )
}

export default MapComponent