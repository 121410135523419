import { Box, Button, IconButton, Modal } from "@mui/material"
import { BoldTitle, FlexBetween } from "../../assets/styles/styledComponents"
import { Close } from "@mui/icons-material"
import ReactLoader from "../common/ReactLoader"

type ModalProps = {
    open: boolean,
    title: any,
    height?: string,
    width?: string,
    btnTitle?: string,
    onClose: Function,
    onSave: Function,
    hideBtn?: boolean,
    top?: string,
    left?: string,
    disabled?: boolean,
    isLoading?: boolean,
    children: any,
    zIndex?: number
}

const NewModal: React.FC<ModalProps> = ({ open, onClose, onSave, title, height, btnTitle, hideBtn, top, left, width, disabled, isLoading, children, zIndex }) => {
    return (
        <Modal open={open} onClose={() => onClose()} sx={{ display: 'flex', justifyContent: 'center', top: top || '20%', zIndex: zIndex || 0 }}>
            <Box sx={{ height: height || '300px', width: width || '500px', background: '#fff', borderRadius: '5px', position: 'relative' }}>
                <FlexBetween borderRadius='5px 5px 0 0' padding='10px' bgColor='#e8e0ff'>
                    <BoldTitle fontFamily={'ConnectAideMain'} fontSize='20px'>{title}</BoldTitle>
                    <IconButton onClick={() => onClose()}>
                        <Close fontSize="small" />
                    </IconButton>
                </FlexBetween>
                <Box padding={'10px'} display={'block'}>
                    {children}
                </Box>
                {!hideBtn &&
                    <div style={{ position: 'absolute', bottom: '0px', right: '0px' }}>
                        <ReactLoader width="20px" height="20px" isLoading={isLoading || false} />
                        <Button
                            disabled={disabled}
                            onClick={(e) => onSave(e)}
                            variant='outlined'
                            sx={{
                                float: 'right',
                                margin: '10px'
                            }}
                        >
                            {btnTitle || 'Save'}
                        </Button>
                    </div>
                }
            </Box>
        </Modal>
    )
}

export default NewModal