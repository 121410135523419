import React, { useEffect, useState } from "react";
import { Box, Button as MuiButton, Dialog, DialogContent, Modal, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, ListItemButton, Select, MenuItem, } from "@mui/material";
import { Tooltip, Chip } from "../../../lib/MUI/mui.components";
import { GridRowId, GridRenderCellParams, useGridApiContext, useGridSelector, gridDetailPanelExpandedRowsContentCacheSelector, GridRowParams, } from "@mui/x-data-grid-pro";
import DataGrid from "../../../components/ui/DataGrid";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { MATCH_COLUMNS, MATCH_SUB_COLUMNS, } from "../../../data/GridColumnDefinitions";
import { IconButton } from "@mui/material";
import Button from "../../../components/ui/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DivContainer } from "../../../components/form/style";
import { ResultDto } from "../../../pages/Patients/Patient";
import useApi_DEPRACATED from "../../../hooks/useApi";
import { BoldTitle, BoxContainer, FlexAlignCenter, Text, } from "../../../assets/styles/styledComponents";
import FeaturedIcon from "../../../components/common/FeaturedIcon";
import FeedbackModal from "../../../components/ui/Modals/components/FeedbackModal";
import MenuPopup from "../../../components/ui/Modals/components/MenuPopup";
import { strings } from "../../../helper/strings";
import { dateTime } from "../../../helper/datetime";
import { BASE_URL, CUSTOM_SCROLL, MATCHING_URL, app_Light_bg, app_blue_light_bg, app_green_light_bg, app_light, app_lighter, app_pink_light, app_pink_light_bg, app_purple, app_red_light_bg, getStatusColor } from "../../../data/constants";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import CloseIcon from "@mui/icons-material/Close";
import VisitMatches from "./VisitMatches";
import useFetcher from "../../../hooks/useFetcher";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import MySnackbar from "../../../components/form/Snackbar";
import FactorSettings from "../../../components/form/FactorSettings/FactorSettings";
import InviteHistory from "./Modals/InviteModal/InviteHistory";
import SearchInput from "../../../components/common/SearchInput/SearchInput";
import useAuth from "../../authentication/hooks/useAuth";
import PatientWeek from "./Modals/PatientWeek/PatientWeek";
import Loader from "../../../components/ui/Loader";
import PreferenceModal from "./Modals/PreferenceModal/PreferenceModal";
import { EventOutlined, PreviewOutlined, RemoveRedEye, TuneOutlined, ViewListOutlined, ViewModuleOutlined, ScheduleSendOutlined } from "@mui/icons-material";
import VisitFilter, { CoordinatorOption } from "../../../components/form/Filters/VisitFilter";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import InviteDetailsModal from "./Modals/InviteModal/InviteDetailsModal";
import NewModal from "../../../components/ui/NewModal";
import Calendar from "../../../pages/Calendar/Calendar";
import { dateSortComparator } from "../../../data/GridColumnDefinitions";
import { MatchTagType, PersonPrefType } from "../../../pages/Preference/types";
import Checkbox from '@mui/material/Checkbox';
import PendingInvites from "./PendingInvites";
import useMultiSelect from "../../../hooks/useMultiSelect";
import { VisitData } from "../../visits/types";

function CustomDetailPanelToggle(
    props: Pick<GridRenderCellParams, "id" | "value">
) {
    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();
    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector
    );

    console.debug("match caregiver component");

    const hasDetail = React.isValidElement(contentCache[id]);

    return (
        <IconButton
            size="small"
            tabIndex={-1}
            disabled={!hasDetail}
            aria-label={isExpanded ? "Close" : "Open"}
        >
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: (theme: any) =>
                        theme.transitions.create("transform", {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
}

type StaffCaseType = {
    hhaVisitId: number;
    patientId: number;
    patientName: string;
    patientNameReverse: string;
    scheduleEndTime: string;
    scheduleStartTime: string;
    visitDate: string;
    visitId: number;
    latitude: number;
    longitude: number;
    staffingPermanency: string;
    schedulePermanency: string;
};

type addressDto = {
    addressLine1: string,
    city: string,
    state: string,
    country: string,
    zipCode: string,
    latitude: string,
    longitude: string,
    full: string
}

const defaultAddress: addressDto = {
    addressLine1: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    latitude: '',
    longitude: '',
    full: '',
}

const VisitsBoard = () => {
    const { getUser } = useAuth();
    const { getList, isLoading, data } = useApi_DEPRACATED("match/visits", BASE_URL);
    const user = getUser();
    const watchFetcher = useFetcher();
    const profileFetcher = useFetcher();
    const feedbackApi = useApi_DEPRACATED("match", BASE_URL);
    const syncVisitApi = useApi_DEPRACATED("HhaData", BASE_URL);
    const matchesApi = useApi_DEPRACATED("match/by_visit", MATCHING_URL);
    const [rows, setRows] = useState<StaffCaseType[]>([]);
    const [filteredRows, setFilteredRows] = useState<StaffCaseType[]>([]);
    const [groupedRows, setGroupedRows] = useState<any[]>([]);
    const [isReadyToLoadGrid, setIsReadyToLoadGrid] = useState(false);
    const [feedbackModalAction, setFeedbackModalAction] = useState(null);
    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([]);
    const [detailPanelRows, setDetailPanelRows] = useState<any>([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [detailSelectedRow, setDetailSelectedRow] = useState<any>(null);
    const [visitId, setVisitId] = useState<number | null>(null);
    const [globalFactorPSettingId, setGlobalFactorPSettingId] = useState<number | undefined>(undefined);
    const [visitDates, setVisitDates] = React.useState<[Dayjs | null, Dayjs | null]>([
        dayjs(),
        dayjs().add(7, 'day')
    ]);
    const [visit, setVisit] = useState<any>(null);
    const [visits, setVisits] = useState<any>(null);
    const [isMatchBoardOpen, setIsModalOpen] = React.useState(false);
    const [selectedGender, setSelectedGender] = React.useState("");
    const [selectedType, setSelectedType] = React.useState<string | undefined>("Hha Exchange");
    const [selectedOffices, setSelectedOffices] = React.useState<officeList[]>([]);
    const [selectedCoordinators, setSelectedCoordinators] = React.useState<CoordinatorOption[]>([]);
    const [coordinators, setCoordinators] = React.useState<CoordinatorOption[]>([]);
    const [offices, setOffices] = React.useState<officeList[]>([]);
    const [openFilter, setOpenFilter] = React.useState(false);
    const [openFactorSettings, setOpenFactorSettings] = React.useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [inviteRow, setInviteRow] = useState<any>();
    const [openSnack, setOpenSnack] = React.useState(false);
    const [profile, setProfile] = useState<any>();
    const [preferredLanguages, set_preferredLanguages] = useState<any>();
    const [missing, setMissing] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [showPatientSchedule, setShowPatientSchedule] = useState<boolean>(false);
    const [showPatientPreference, setShowPatientPreference] = useState<boolean>(false);
    const [status, setStatus] = useState('unstaffed');
    const [history, setHistory] = useState<any[]>([]);
    const [loadedOnce, setLoadedOnce] = useState(false);
    const [caseBased, setCaseBased] = useState(true);
    const [openCaseVisitsModal, setOpenCaseVisitsModal] = useState(false);
    const [openPending, setOpenPending] = useState(false);
    const [caseVisits, setCaseVisits] = useState<any[]>([]);
    const [openInvitesList, setOpenInvitesList] = useState(false);
    const [openInvitesDetails, setOpenInvitesDetails] = useState(false);
    const [invites, setInvites] = useState<any[]>([])
    const [patientPendingInvitesCount, setPatientPendingInvitesCount] = useState<number[]>([])
    const [permanencyFilter, setPermanencyFilter] = useState<any>('All');
    const [selectedInvite, setSelectedInvite] = useState<any>()
    const handleCloseModal = () => { setOpenModal(false); };
    const handleOpenFilter = () => { setOpenFilter(true); };
    const handleOpenSnack = () => { setOpenSnack(true); };
    const handleCloseSnack = () => { setOpenSnack(false); };
    const [clickedStatus, setClickedStatus] = useState(0);
    const handleOpenInvite = (row: any) => {
        setInviteRow(row)
        getInviteHistory(row.visitId)
        setOpenModal(true)
    };
    const [address, setAddress] = useState<addressDto>(defaultAddress)
    const [filterIsDirty, setFilterIsDirty] = useState(false)
    const [selectedIsDirty, setSelectedIsDirty] = useState(false)
    // preferences
    const [patientPreferences, setPatientPreferences] = React.useState<PersonPrefType[]>([])
    const [preferences, setPreferences] = useState<MatchTagType[]>([]);
    const [isRequiredPreferencesMissing, set_isRequiredPreferencesMissing] = useState<boolean>(false);
    const [matchVisitsSelectedIds, setTempVisitsSelectedIds] = useState<any[]>([]);
    useEffect(() => {
        setFilterIsDirty(true)
    }, [address, selectedCoordinators, selectedGender, selectedOffices, visitDates])

    const resultOptions = [
        { name: 'unstaffed', label: 'Unstaffed' },
        { name: 'staffed', label: 'Staffed' },
        { name: 'all', label: 'All' },
    ]

    const inviteFetcher = useFetcher();
    const officesFetcher = useFetcher("filter/offices");
    const coordinatorsFetcher = useFetcher("filter/coordinators");
    const defaultOfficesFetcher = useFetcher("filter/default_offices");
    const defaultCoordinatorsFetcher = useFetcher("filter/default_coordinators");
    const ptPrefFetcher = useFetcher();
    const prefFetcher = useFetcher();
    const langsFetcher = useFetcher();
    const [didLoad, setDidLoad] = useState(false);
    const multiSelection = useMultiSelect([])
    const [tempSelectedIdsFromCalendar, setTempSelectedIdsFromCalendar] = useState<string[]>([]);
    const [patientId, set_patientId] = useState<number | undefined>(undefined);
    const permanencies = ['All', 'Temporary', 'Permanent']

    // useEffect(() => {
    //     if (!didLoad) {
    //         console.log("225")
    //         loadVisits();
    //         setDidLoad(true);
    //     }
    // }
    // )

    useEffect(() => {
        setFilterIsDirty(true)
    }, [address, selectedCoordinators, selectedGender, selectedOffices, visitDates])

    useEffect(() => {
        setSelectedIsDirty(true)
    }, [multiSelection.selected])

    useEffect(() => {
        if (visit == null)
            return;

        langsFetcher.get(`Language/patient/${visit?.patientId}`).then((res: any) => {
            let langs = res.data.data
            set_preferredLanguages(langs);
        })
    }, [visit])

    // const wrapperSetGlobalProfileId = useCallback( (val:any) => {
    //   setGlobalFactorSettingId(val);
    // }, [setGlobalFactorSettingId]);

    type officeList = {
        id: string;
        officeName: string;
    };

    useEffect(() => {
        const results = rows?.filter((item: StaffCaseType) => {
            const values = Object.values(item);
            return values.some((value) => String(value).toLowerCase().includes(searchTerm.toLowerCase()));
        }).filter((item: StaffCaseType) => {
            if ((item.staffingPermanency == 'Permanent' && permanencyFilter == 'Temporary') || (item.staffingPermanency == 'Temporary' && permanencyFilter == 'Permanent')) {
                return false;
            }
            return true;
        });

        setFilteredRows(results)
    }, [searchTerm, rows, permanencyFilter])

    useEffect(() => {
        if (visit) {
            profileFetcher.get(`patient/${visit?.patientId}`).then((response: any) => {
                setProfile(response.data?.data ?? response.data)
            })
        }
    }, [visit])

    useEffect(() => {
        console.debug(["globalFactorSettingId", globalFactorPSettingId]);
    }, [globalFactorPSettingId]);

    useEffect(() => {
        setOffices(officesFetcher.data || []);
        setCoordinators(coordinatorsFetcher.data || []);
        setSelectedOffices(defaultOfficesFetcher.data || []);
        setSelectedCoordinators(defaultCoordinatorsFetcher.data || []);
    }, [
        officesFetcher.data,
        coordinatorsFetcher.data,
        defaultOfficesFetcher.data,
        defaultCoordinatorsFetcher.data,
    ]);
    useEffect(() => {
        if (defaultCoordinatorsFetcher.data == null || defaultOfficesFetcher.data == null)
            return;

        loadVisits(undefined, (defaultOfficesFetcher.data || []), (defaultCoordinatorsFetcher.data || []));
    }, [
        defaultOfficesFetcher.data,
        defaultCoordinatorsFetcher.data
    ])

    useEffect(() => {
        !!visitId && getMatches();
    }, [visitId]);

    useEffect(() => {
        if (!detailPanelRows || detailPanelRows.length < 1) {
            getDetailRows();
        }
    }, [matchesApi?.data, detailPanelRows]);

    const getTimeString = (date: Date | string, convertToLocal?: boolean): string => {
        const dateObj: Date = new Date(date);
        const timeStr: string = convertToLocal ? dateObj.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        }) : dateTime.getTimeString(dateObj);

        return timeStr;
    };

    useEffect(() => {
        let isReady =
            data?.list !== null &&
            data?.list !== undefined &&
            Array.isArray(data.list);
        setRows(data?.list);
        setIsReadyToLoadGrid(isReady);
    }, [data, data?.list]);

    const getFileName = () => {
        if (!visit) return "export";
        const dateObj: Date = new Date(visit.scheduleStartTime);
        const timeStr: string = dateObj.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });

        const month: number = dateObj.getMonth() + 1; // Month is zero-indexed, so we add 1
        const day: number = dateObj.getDate();
        const monthStr: string = month < 10 ? `0${month}` : `${month}`; // Add leading zero if month is single digit
        const dayStr: string = day < 10 ? `0${day}` : `${day}`; // Add leading zero if day is single digit
        const dateStr: string = `${monthStr}-${dayStr}`;

        return visit.patientNameReverse + " " + dateStr + " " + timeStr;
    };

    const isRequiredMissing = (prefId: number): boolean => {
        if (!preferences || !patientPreferences)
            return false;

        const pref = preferences.find(p => p.id === prefId);
        const persPref = patientPreferences.find(p => p.preferenceId === prefId);

        if (!pref?.isRequiredAsk || persPref?.id != 0)
            return false;
        else
            return true;
    }

    useEffect(() => {
        if (!preferences || !patientPreferences)
            return;

        var anyIsRequired = patientPreferences
            .some(p => isRequiredMissing(p.preferenceId));

        set_isRequiredPreferencesMissing(anyIsRequired);
    }, [patientPreferences, preferences])

    useEffect(() => {
        let sorted = organizeVisitsByPatient(filteredRows)
        //.sort((a, b) => a.patientNameReverse.localeCompare(b.patientNameReverse))
        setGroupedRows(sorted)
    }, [filteredRows, permanencyFilter])

    useEffect(() => {
    }, [preferences])

    // paitnet preference
    useEffect(() => {
        if (!visit)
            return;
        getPatientPreferences();
    }, [visit, visit?.patientId]);
    const getPatientPreferences = () => {
        ptPrefFetcher.get(`preference/patient/${visit.patientId}`).then(res => {
            setPatientPreferences(res.data.data)
        })
    };

    // general preferences
    useEffect(() => {
        getPreferences();
    }, []);
    const getPreferences = () => {
        prefFetcher.get(`preference`).then(res => {
            setPreferences(res.data.data)
        })
    };

    const checkForMissing = () => {
        let newMissing = [...missing];
        if (!!profile && (!profile.languages && !preferredLanguages?.length) && !newMissing.some((m: any) => m.name === 'Language')) {
            newMissing.push({ name: 'Language' })
        }
        if (!!profile && !profile.gender && !newMissing.some((m: any) => m.name === 'Gender')) {
            newMissing.push({ name: 'Gender' });
        }
        if (!!preferences && !!patientPreferences &&
            !!isRequiredPreferencesMissing && !newMissing.some((m: any) => m.name === 'Important Preferences')) {
            newMissing.push({ name: 'Important Preferences' });
        }
        setMissing(newMissing);
    }

    useEffect(() => {
        checkForMissing();
    }, [visit, profile, preferences, patientPreferences]);

    useEffect(() => {
        if (isMatchBoardOpen == false)
            multiSelection.setSelected(caseVisits.map((v: any) => v.visitId));
    }, [caseVisits, isMatchBoardOpen]);

    const handleCloseDetailsModal = () => {
        setIsModalOpen(false);
        multiSelection.clear();
        setMissing([])
        setVisit(null)
    };

    const getInviteHistory = (visitId: number) => {
        inviteFetcher.get(`Invite/history?visitId=${visitId}`).then(res => {
            setHistory(res.data.data);
        })
    }
    const getDetailPanelContent = ({ row }: any) => {
        const customActionButton = (
            <FlexAlignCenter>
                <Button label="Load" onClick={getMatches} />
                <Button label="Sync" onClick={handleSyncVisit} />
            </FlexAlignCenter>
        );
        return (
            <BoxContainer margin="20px">
                <DataGrid
                    data={{
                        columns: matchSubCols(),
                        rows: detailPanelRows,
                    }}
                    loading={matchesApi.isLoading || syncVisitApi.isLoading}
                    options={{
                        hideExport: false,
                        exportFileName: getFileName,
                        hideFiltering: true,
                        hideColumns: true,
                        headerBGColor: "rgba(25, 118, 210, 0.08)",
                        headerTextColor: "#222",
                        height: "315px",
                        pageSize: 5,
                        toolbarComponent: () => customActionButton,
                    }}
                />
            </BoxContainer>
        );
    };

    const getMatches = () => {
        if (!!matchesApi && matchesApi.isLoading) matchesApi.abort();
        if (!!visitId) matchesApi.getList(`/${visitId}`);
    };

    let initialValue = new ResultDto(); // initializing the prototype object, so the AddEditModal fields will be populated based on the prototype object properties dynamically (see also List.tsx)

    const submitFeedback = (data?: {
        action: string | null;
        description: string;
    }) => {
        if (!!data) {
            feedbackApi
                .create(
                    {
                        visitId: detailSelectedRow?.visitId,
                        description: data.description,
                        updateType: data.action,
                        caregiverId: detailSelectedRow?.caregiverId,
                        reportedBy_UserId: user?.userId,
                    },
                    `/${data.action}`
                )
                .then(() => {
                    if (data.action?.toLowerCase() === "decline") {
                        //visitApi.removeVisitMatch(detailSelectedRow.caregiverId);
                        setDetailPanelRows(
                            detailPanelRows.filter(
                                (row: any) =>
                                    row.caregiverId !==
                                    detailSelectedRow.caregiverId
                            )
                        );
                    }
                    closeFeedbackModal();
                });
        } else {
            closeFeedbackModal();
        }
    };

    const handleSyncVisit = () => {
        syncVisitApi.create({}, `/visit/${visitId}`).then(() => {
            getMatches();
        });
    };

    const closeFeedbackModal = () => {
        setFeedbackModalAction(null);
        setDetailSelectedRow(null);
    };

    const handleDetailPanelExpandedRowIdsChange = React.useCallback(
        (newIds: GridRowId[]) => {
            setDetailPanelExpandedRowIds(newIds);
            matchesApi.clearData();
            setDetailPanelRows([]);
        },
        []
    );

    const handleWatchCase = (params: any, action: string) => {
        watchFetcher.get(`Watch/${action}/${params.patientId}`).then(() => {
            handleOpenSnack();
            loadVisits();
        });
    };

    // Find caregivers for selected visits 
    const findCaregiversForSelectedShifts = () => {
        return (
            <FlexAlignCenter>
                <Button
                    label={isMatchBoardOpen ? 'Apply' : 'Find caregivers'}
                    onClick={() => {
                        if (multiSelection.count() !== 0) {
                            setVisit(caseVisits?.[0]);
                            setIsModalOpen(true);
                            setOpenCaseVisitsModal(false);
                            setSelectedIsDirty(false)
                        }
                    }}
                />
            </FlexAlignCenter>
        );
    };

    const organizeVisitsByPatient = (visits: any) => {
        const visitsByPatient: Record<string, any> = {};
        if (visits != undefined) {
            visits.forEach((visit: any) => {
                const { patientId, patientNameReverse, hhaPatientId, hhxAdmissionId, staffingPermanency, address } = visit;
                // add for seperated to key if temp or perm so can do it
                const key = `${patientId}${permanencyFilter.toLowerCase() !== "merged" ? "_" + staffingPermanency : ""}`;

                if (!visitsByPatient[key]) {
                    visitsByPatient[key] = {
                        patientId,
                        patientNameReverse,
                        hhaPatientId,
                        hhxAdmissionId,
                        address,
                        invites: [],
                        visitsCount: 0,
                        visits: []
                    };
                }

                visitsByPatient[key].pendingInvites = visit.invitesTotalOpenForPatient;
                visitsByPatient[key].invitesAccepted = visit.invitesAccepted_InviteCode || 0;
                visitsByPatient[key].invitesSend = visit.invitesSend_InviteCode || 0;
                visitsByPatient[key].visitsCount++;
                visitsByPatient[key].visits.push(visit);
                visitsByPatient[key].staffingPermanency =
                    !visitsByPatient[key].visits.some((v: VisitData) => v.staffingPermanency == 'Permanent') ? 'Temp' : // no permanent
                        !visitsByPatient[key].visits.some((v: VisitData) => v.staffingPermanency == 'Temporary') ? 'Perm' : // no permanent
                            "Both";

                // Process invites and only add unique caregiverId invites
                if (visit.invites != null) {
                    const uniqueCaregiverIds = new Set(visit.invitesSent.map((invite: any) => invite.caregiverId));
                    visitsByPatient[key].invites.forEach((invite: any) => {
                        if (!uniqueCaregiverIds.has(invite.caregiverId)) {
                            visitsByPatient[key].invites.push(invite);
                            uniqueCaregiverIds.add(invite.caregiverId);
                        }
                    });
                }
            });

        }
        const result = Object.values(visitsByPatient);

        return result;
    }


    const handleOpenCaseVisitsModal = (visits: any) => {
        if (isMatchBoardOpen) { setTempVisitsSelectedIds(multiSelection.selected); }
        setOpenCaseVisitsModal(true)
        if (!!visits?.length) {
            set_patientId(visits[0].patientId);
        }
        const mappedVisits = visits.map((v: any) => ({
            ...v,
            id: v.visitId,
        }));
        setCaseVisits(mappedVisits);
        if (!isMatchBoardOpen) {
            multiSelection.setSelected(mappedVisits.map((v: any) => v.visitId));
        }
    };

    const handleOpenPending = (visit: any) => {
        setVisit(visit);
        setOpenPending(true)
    }

    const invitesFetcher = useFetcher();

    const handleOpenInvitesList = (row: any) => {
        if (row.invitesSend < 1)
            return;

        invitesFetcher.get(`Invite/open/${row.patientId}?from=${visitDates[0]?.toISOString()}&to=${visitDates[1]?.toISOString()}`).then(res => {
            setInvites(res.data.data)
            setOpenInvitesList(true)
        })
    }

    const caseCols = () => {
        return [
            { field: 'hhxAdmissionId', headerName: 'HHX Admission Id', },
            { field: 'patientNameReverse', headerName: 'Patient', navUrl: "patient/{patientId}/details" },
            { field: 'address', headerName: 'Address', hide: true },
            {
                field: 'staffingPermanency', headerName: 'Staffing', flex: 0.03,
                renderCell: (params: any) => (
                    <Tooltip title={params.row.staffingPermanency == "Perm" ? "Permanent shift" : "Temp shift"} enterTouchDelay={0}>
                        <Chip
                            sx={{
                                height: '20px',
                                borderRadius: '3px',
                                cursor: 'default',
                                //background: params.row.staffingPermanency == "Perm" ? app_green_light_bg : app_blue_light_bg,
                                background: params.row.staffingPermanency == "Perm" ? app_Light_bg : app_pink_light_bg,
                                marginRight: '2px',
                                '.MuiChip-label': {
                                    paddingLeft: '7px',
                                    paddingRight: '7px',
                                }
                            }}
                            label={params.row.staffingPermanency == "Perm" ? 'PERM' : 'TEMP'}
                        />
                    </Tooltip>
                    // <Text>{params.row.staffingPermanency}</Text>
                )

            },
            // { field: 'visitsCount', headerName: 'Visits', },
            {
                field: "Invites", flex: 0.03,
                headerName: 'Invites',
                renderCell: (params: any) => {
                    // pendingInvites
                    const totals = params.row.invitesAccepted + '/' + params.row.invitesSend
                    return (
                        <Tooltip title="Accepted invites vs. total invites">
                            <MuiButton
                                variant='text' sx={{ textTransform: "none", fontSize: '13px' }}
                                onClick={() => handleOpenPending(params.row)}
                            >
                                {totals}
                            </MuiButton>
                        </Tooltip>
                    )
                },
            },
            {
                field: "see", headerName: 'Visits', flex: 0.03,
                renderCell: (params: any) => (
                    <Tooltip title="See Visits">
                        <MuiButton onClick={() => { handleOpenCaseVisitsModal(params.row.visits) }} endIcon={<PreviewOutlined sx={{ width: '20px', height: '20px' }} fontSize="small" />}>
                            {params.row.visitsCount}
                        </MuiButton>
                    </Tooltip>
                )
            },
            {
                field: "Caregivers", flex: 0.02,
                renderCell: (params: any) => (
                    <DivContainer>
                        <FeaturedIcon
                            fontSize={"small"}
                            params={params}
                            icon={"People"}
                            color={"primary"}
                            onClick={() => {
                                if (params.row) {
                                    // setVisitId(params.row.visitId)
                                    setVisit(params.row.visits[0]);
                                    setCaseVisits(params.row.visits.map((v: any) => {
                                        return {
                                            ...v,
                                            id: v.visitId
                                        }
                                    }));
                                    multiSelection.setSelected(params.row.visits.map((v: any) => v.visitId));
                                    setVisits(params.row.visits);
                                    setIsModalOpen(true);
                                }
                            }}
                        />
                    </DivContainer>
                ),
            },
        ]
    }

    const caseVisitsCols = () => {
        return [
            {
                field: 'selected',
                headerName: 'Select',
                width: 150,
                renderCell: (params: any) => (
                    <Checkbox
                        checked={multiSelection.hasValue(params.row.visitId)}
                        onChange={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            multiSelection.toggleItem(params.row.visitId);
                        }}
                    />
                ),
            },
            { field: 'hhaVisitId', headerName: 'HHX Visit Id', },
            {
                field: 'visitDate', headerName: 'Visit Date',
                renderCell: (params: any) => (dateTime.getShortDisplayDate(params.row.visitDate)),
                sortComparator: dateSortComparator,
            },
            {
                field: 'scheduleStartTime', headerName: 'Start Time',
                renderCell: (params: any) => (dateTime.getTimeString(params.row.scheduleStartTime)),
                sortComparator: dateSortComparator
            },
            {
                field: 'scheduleEndTime', headerName: 'End Time',
                renderCell: (params: any) => (dateTime.getTimeString(params.row.scheduleEndTime)),
                sortComparator: dateSortComparator
            },
            { field: 'invitesSend', headerName: 'Invites Sent', renderCell: (params: any) => (<span style={{ width: '100%', cursor: 'pointer' }} onClick={() => handleOpenInvitesList(params.row)}>{params.row.invitesSend}</span>) },
            { field: 'permanency', headerName: 'Staffing', renderCell: (params: any) => { return (params.row.permanency == 'Permanent' ? 'Perm' : 'Temp') } }
        ]
    }

    const matchCols = () => {
        return [...MATCH_COLUMNS,
        {
            field: 'permanency', headerName: 'Staffing', flex: 0.03,
            renderCell: (params: any) => (
                <Text>{params.row.permanency == 'Permanent' ? 'Perm' : 'Temp'}</Text>
            )
        },
        {
            field: "watchCase", flex: 0.04,
            renderCell: (params: any) => {
                if (!defaultCoordinatorsFetcher?.data?.length) return;
                if (!params.row.isWatching) {
                    return (
                        <Tooltip title="Not Watching">
                            <IconButton>
                                <VisibilityOffIcon
                                    onClick={() => handleWatchCase(params.row, 'add')}
                                    fontSize="small"
                                />
                            </IconButton>
                        </Tooltip>
                    );
                }
                if (params.row.isWatching) {
                    return (
                        <Tooltip title="Watching">
                            <IconButton>
                                <VisibilityIcon
                                    onClick={() => handleWatchCase(params.row, 'remove')}
                                    fontSize="small"
                                    color="primary"
                                />
                            </IconButton>
                        </Tooltip>
                    );
                }
            },
        },
        {
            field: "Invites", flex: 0.03,
            headerName: 'Invites',
            renderCell: (params: any) => {
                const totals = params.row.invitesAccepted + '/' + params.row.invitesSend
                return (
                    <Tooltip title="Accepted invites vs. total invites">
                        <MuiButton
                            variant='text' sx={{ textTransform: "none", fontSize: '13px' }}
                            onClick={() => handleOpenInvite(params.row)}
                        >
                            {totals}
                        </MuiButton>
                    </Tooltip>
                )
            },
        },
        {
            field: "Caregivers", flex: 0.02,
            renderCell: (params: any) => (
                <Tooltip title="Find Caregiver">
                    <DivContainer>
                        <FeaturedIcon
                            fontSize={"small"}
                            params={params}
                            icon={"People"}
                            color={"primary"}
                            onClick={() => {
                                if (params.row) {
                                    // setVisitId(params.row.visitId)
                                    setVisit(params.row);
                                    setIsModalOpen(true);
                                }
                            }}
                        />
                    </DivContainer>
                </Tooltip>
            ),
        },
        ];
    };
    const handleOpenFactorSettings = () => {
        setOpenFactorSettings(true);
    };

    const matchSubCols = () => {
        return [
            ...MATCH_SUB_COLUMNS,
            // {
            //     field: "isAssignee",
            //     headerName: "Assigned",
            //     flex: 0.7,
            //     renderCell: (params: any) =>
            //         params.row.isAssignee ? <span> Yes </span> : <span></span>,
            // },
            {
                field: "bestMatch",
                flex: 0.3,
                renderCell: (params: any) => {
                    if (params.row.isBestMatch) {
                        return <Chip label="Best Match" color="success" />;
                    }
                    return null;
                },
            },

            {
                field: "inviteIcon",
                flex: 0.3,
                renderCell: (params: any) => (
                    <Tooltip title={!!params.row.wasInvited ? "This caregiver was invited to this visit" : "Invite"}>
                        <DivContainer>
                            <FeaturedIcon
                                fontSize={"small"}
                                params={params}
                                icon={"Send"}
                                color={
                                    !!params.row.wasInvited ? "success" : "primary"
                                }
                                onClick={() => handleOpenInvite(params.row)}
                            />
                        </DivContainer>
                    </Tooltip>
                ),
            },
            {
                field: "infoIcon",
                flex: 0.3,
                renderCell: (params: any) => (
                    <Tooltip title={params.row.summary}>
                        <DivContainer>
                            <FeaturedIcon
                                fontSize={"small"}
                                params={params}
                                icon={"Checklist"}
                                color={
                                    !!params.row.summary ? "primary" : "default"
                                }
                            />
                        </DivContainer>
                    </Tooltip>
                ),
            },
            {
                field: "menu",
                flex: 0.3,
                renderCell: (params: any) => (
                    <FeaturedIcon
                        fontSize={"small"}
                        params={params}
                        icon={"Menu"}
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                            setDetailSelectedRow(params.row);
                            setAnchorEl(e.currentTarget);
                        }}
                    />
                ),
            },
        ];
    };

    const handleCloseMenu = (selected = null) => {
        if (selected) setFeedbackModalAction(selected);
        setAnchorEl(null);
    };

    const reshuffle = () => {
        loadVisits(false);
    };

    const findMatchesWithFilters = (params: any) => {
        // setDetailPanelExpandedRowIds([])
        getList(
            `/${visitDates[0]?.toString()}/false${params}&fromDate=${visitDates[0]?.toString()}&tillDate=${visitDates[1]?.toString()}`
        );
    };

    const loadVisits = async (sync: boolean = false, offices: officeList[] = [], coordinators: CoordinatorOption[] = []) => {
        setFilterIsDirty(false)
        setDetailPanelExpandedRowIds([]);
        let syncPrm = sync ? "?sync=true" : "";
        syncPrm = sync == true ? "/true" : "/false";

        let query = "?";
        const officesForQuery = !!offices.length ? offices : selectedOffices;
        const coordinatorsForQuery = !!coordinators.length ? coordinators : selectedCoordinators;
        const officeQuery = !!officesForQuery?.length ? `Office=${officesForQuery.map((o: any) => o.officeName)}` : "";
        // console.log("officeQuery", officeQuery);
        const genderQuery =
            selectedGender !== "" && selectedGender !== undefined
                ? `${(!!officeQuery ? '&' : '')}Gender=${selectedGender}`
                : "";
        // console.log("genderQuery", genderQuery);
        const coordinatorsQuery = !!coordinatorsForQuery?.length
            ? `${((!!officeQuery || !!genderQuery) ? '&' : '')}Coordinator=${coordinatorsForQuery.map((c: any) => c.name)}`
            : "";
        // console.log("coordinatorsQuery", coordinatorsQuery);
        const addressQuery = address.full != ''
            ? `${((!!officeQuery || !!genderQuery || !!coordinatorsQuery) ? '&' : '')}` +
            `Address.AddressLine1=${address.addressLine1}` +
            `&Address.City=${address.city}` +
            `&Address.State=${address.state}` +
            `&Address.Country=${address.country}` +
            `&Address.ZipCode=${address.zipCode}` +
            `&Address.Latitude=${address.latitude}` +
            `&Address.Longitude=${address.longitude}` : "";
        // console.log("coordinatorsQuery", addressQuery);
        query = `${query}${officeQuery}${genderQuery}${coordinatorsQuery}${addressQuery}`;
        // console.log("query", query);
        await getList(
            `/${visitDates[0]?.format("YYYY-MM-DDTHH:mm:ss")}`
            + `${syncPrm}${query}`
            + `&fromDate=${visitDates[0]?.format("YYYY-MM-DDTHH:mm:ss")}`
            + `&tillDate=${visitDates[1]?.format("YYYY-MM-DDTHH:mm:ss")}`
            + `&status=${status}`
            + `&type=${selectedType == 'Hha Exchange' ? 'hhx' : (selectedType == undefined ? null : selectedType)}`
        );
        setLoadedOnce(true);
    };

    const getDetailRows = () => {
        let matches: any = [];
        let recommended: any = {
            ...matchesApi?.data?.list?.recommendedMatch,
        };
        matches = matchesApi?.data?.list?.matches.map((row: any) => {
            return {
                ...row.caregiver,
                ...row.visit,
                minutesAvailable: row.minutesAvailable,
                percentAvailable: `${row.percentAvailable}%`,
                isAssignee: row.isAssignee,
                flags: row.flags[0],
                summary: row.summary,
                distance: row.distance,
            };
        });
        //matches = matches?.filter((match: any) => match.caregiverId !== recommended?.caregiver?.caregiverId);
        //if (matches?.length > 0) {
        //if (!!recommended?.caregiver) {
        //matches.unshift({ ...recommended?.caregiver, ...recommended?.visit, flags: recommended?.flags, summary: recommended?.summary, isBestMatch: true })
        //}
        //}
        setDetailPanelRows(matches);
    };


    const handleSearch = (query: string) => {
        setSearchTerm(query)
    }

    const detailsPanelCustomActionButton = <FlexAlignCenter>
        <Button
            label="Load"
            onClick={getMatches}
        />
        <Button
            label="Sync"
            onClick={handleSyncVisit}
        />
    </FlexAlignCenter>;

    const isSuperAdminUser = () => {
        if (!user?.username)
            return false;

        const email = user?.username;
        if (email === 'daminov@emerest.com' || email?.endsWith('techonsoft.com')) {
            return true
        } else {
            return false;
        }
    }


    const customActionButton = <FlexAlignCenter>
        {/* <Broadcast /> */}
        <Tooltip placement="top" title="Staffing Term">
            <Select
                size="small"
                onChange={(e: any) => setPermanencyFilter(e.target.value)}
                sx={{
                    width: '150px',
                    marginLeft: '5px'
                }}
                value={permanencyFilter}
            >
                {permanencies.map((option, index) => (
                    <MenuItem key={index} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </Tooltip>
        <Tooltip title={caseBased ? 'Visit Based View' : 'Case Based View'}>
            <IconButton onClick={() => setCaseBased(!caseBased)}>
                {caseBased ? <ViewListOutlined color="primary" /> : <ViewModuleOutlined color="primary" />}
            </IconButton>
        </Tooltip>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
                calendars={1}
                slots={{ field: SingleInputDateRangeField }}
                slotProps={{ textField: { size: 'small' } }}
                sx={{
                    padding: 0.5,
                    width: "210px",
                    '> .MuiOutlinedInput-root': {
                        height: '30px',
                    }
                }}
                disablePast
                value={visitDates}
                onChange={(value) => setVisitDates(value)}
                closeOnSelect={false}
            />
        </LocalizationProvider>
        {
            isSuperAdminUser() && <Tooltip title="Factor Settings" placement="bottom">
                <IconButton color='primary' onClick={handleOpenFactorSettings}>
                    <SettingsIcon />
                </IconButton>
            </Tooltip>
        }
        {/* <Tooltip title="Sync & Load" placement="bottom">
        <IconButton color='primary' onClick={() => findMatches(true)}>
          <CloudSyncOutlinedIcon />
      </IconButton>
    </Tooltip>  */}
        <Tooltip title="Load" placement="bottom">
            <IconButton color={filterIsDirty ? 'error' : 'primary'} onClick={() => loadVisits()}>
                <SyncOutlinedIcon />
            </IconButton>
        </Tooltip>
        <Tooltip title="Filters" placement="bottom">
            <IconButton color='primary' onClick={handleOpenFilter}>
                <TuneOutlined />
            </IconButton>
        </Tooltip>
    </FlexAlignCenter >

    const handleClickStatus = (option: any) => {
        setStatus(option)
        setClickedStatus(clickedStatus + 1)
    }

    useEffect(() => {
        if (clickedStatus == 0)
            return;

        loadVisits(false);
    }, [clickedStatus])

    const headerTitle =
        <div style={{ height: '45px' }}>
            {resultOptions.map((option: any) =>
                <Chip onClick={() => handleClickStatus(option.name)} sx={{ borderRadius: '5px', margin: '5px 10px 0px 0px', backgroundColor: status == option.name ? '#7846ff' : 'white', border: '1px solid #7846ff', color: status == option.name ? 'white' : '#7846ff' }} label={option.label} />
            )}
        </div>

    const getMatchesTitle = (visitDates: any) => {
        const from = dateTime.getYearDate(visitDates[0]?.toString());
        const to = dateTime.getYearDate(visitDates[1]?.toString())

        if (to.match(from))
            return from;

        return from + " - " + to
    }

    const handleOpenInviteDetails = (invite: any) => {
        setOpenInvitesDetails(true)
        setSelectedInvite(invite)
    }

    const handleCloseInviteList = () => {
        setOpenInvitesList(true)
        setInvites([])
    }

    const handleCloseCaseVisitsModal = () => {
        if (isMatchBoardOpen == false)
            multiSelection.clear()
        if (isMatchBoardOpen)
            multiSelection.setSelected(matchVisitsSelectedIds)
        setOpenCaseVisitsModal(false)
    }

    // This is to reload when applied from PatientSchedule 
    // The VisitMatches reloads whenever setSelectedIsDirty is changed to false
    useEffect(() => {
        if (!showPatientSchedule)
            setSelectedIsDirty(false)
    }, [showPatientSchedule])

    const handleClosePatientSchedule = () => {
        setShowPatientSchedule(false)
    }

    useEffect(() => {
        // console.log(groupedRows);
    }, [groupedRows])

    useEffect(() => {
        // console.log(filteredRows);
    }, [filteredRows])

    return (
        <div style={{ padding: "10px 10px 10px 10px" }}>
            <Loader isLoading={isLoading || prefFetcher.isLoading || profileFetcher.isLoading || langsFetcher.isLoading || ptPrefFetcher.isLoading} />
            <SearchInput getSearchQuery={handleSearch} />
            <DivContainer>
                <DataGrid
                    title={headerTitle}
                    data={{
                        columns: caseBased ? caseCols() : matchCols(),
                        rows: caseBased ? groupedRows : filteredRows || []
                    }}
                    options={{
                        rowHeight: 45,
                        toolbarComponent: customActionButton,
                        hideExport: true,
                        hideToolbarIconText: true,
                        noRowsOverlayText: loadedOnce ? 'No data found' : '',
                    }}
                    //getDetailPanelContent={(params: any) => { return getDetailPanelContent(params) }}
                    //getDetailPanelContent={getDetailPanelContent}
                    detailPanelExpandedRowIds={detailPanelExpandedRowIds.length > 0 ? [detailPanelExpandedRowIds.at(-1)] : []}
                    onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                // onRowClick={(params: any) => {
                //   console.log(params?.row?.id);
                //   if (!!params?.row) {
                //     setIsModalOpen(true);
                //     setVisit(params.row);
                //     //setDetailPanelExpandedRowIds([params?.row?.caregiverId])
                //   }
                // }}
                />
                <FeedbackModal
                    title={strings.firstLetterUpperCase(feedbackModalAction ?? '')}
                    action={feedbackModalAction}
                    isLoading={feedbackApi.isLoading}
                    open={!!feedbackModalAction}
                    onClose={submitFeedback}
                />
                <MenuPopup
                    id={'mtch-febck'}
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    options={[
                        { label: 'Accept', value: 'accept' },
                        { label: 'Decline', value: 'decline' },
                        { label: 'Feedback', value: 'feedback' },
                        { label: 'Update', value: 'update' },
                    ]}
                    onCloseMenu={handleCloseMenu}
                />
            </DivContainer>
            <Dialog
                open={isMatchBoardOpen}
                onClose={handleCloseDetailsModal}
                fullWidth
                fullScreen
            // sx={{
            //     '& .MuiDialog-paper': {
            //         width: '90%',
            //         height: '90%',
            //     },
            // }}
            >
                <DialogContent>
                    {/* {!!missing && missing.length > 0 && <Box borderRadius={10} height={'20px'} color={'red'} textAlign={'center'} display={'flex'} justifyContent={'center'}>
                        <InfoOutlined fontSize="small" sx={{ marginRight: '10px' }} />
                        <Text color='red'>
                            {"Please update the patients' "}
                            {
                                missing.map((name: any, i: number) => `${missing.length > 1 && missing.length == i + 1 ? '& ' : ''}${name.name}`).join(", ")
                            } for accurate results.
                        </Text>
                    </Box>} */}
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        {!!visit && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <BoldTitle fontWeight='200px'>
                                    <a
                                        href={`/patient/${visit.patientId}/details`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: 'black' }}
                                    >
                                        {visit.patientName}
                                    </a>
                                    {" | "}
                                    {getMatchesTitle(visitDates)}
                                    {/* {dateTime.getYearDate(visitDates[0]?.toString())} {" - "} {dateTime.getYearDate(visitDates[1]?.toString())} */}
                                    {/* {dateTime.getDateString(visit.visitDate)}{" "}
                                    {getTimeString(visit.scheduleStartTime, false)} - {getTimeString(visit.scheduleEndTime, false)} */}
                                </BoldTitle>
                                <FeaturedIcon
                                    fontSize={"small"}
                                    icon={"Schedule"}
                                    color={"primary"}
                                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                                        setShowPatientSchedule(true)
                                    }}
                                />
                                {/* <IconButton onClick={() => setShowPatientPreference(true)}>
                                    <Rule color="primary" fontSize="small" />
                                </IconButton> */}
                                <FlexAlignCenter>
                                    { }
                                    <MuiButton onClick={() => { handleOpenCaseVisitsModal(caseVisits) }} startIcon={<PreviewOutlined sx={{ width: '20px', height: '20px' }} fontSize="small" />}>
                                        <div style={{ alignItems: 'center', width: '0px' }}>
                                            <span>{multiSelection.count()}</span>
                                        </div>
                                    </MuiButton>
                                </FlexAlignCenter>
                                <FlexAlignCenter>
                                    { }
                                    <MuiButton onClick={() => handleOpenPending(visit)} startIcon={<ScheduleSendOutlined color={patientPendingInvitesCount ? "primary" : "disabled"} sx={{ width: '20px', height: '20px' }} fontSize="small" />}>
                                        <div style={{ alignItems: 'center', width: '10px' }}>

                                        </div>
                                    </MuiButton>
                                </FlexAlignCenter>
                                {/* {!!missing && missing.length > 0 && <InfoOutlined fontSize="small" sx={{ marginRight: '10px', color: 'red' }} />} */}
                                {!!missing && missing.length > 0 && <Text color='red'>
                                    {"Please update the patients' "}
                                    {
                                        missing.map((name: any, i: number) => `${missing.length > 1 && missing.length == i + 1 ? '& ' : ''}${name.name}`).join(", ")
                                    } for accurate results.
                                </Text>}
                            </div>
                        )}

                        <IconButton aria-label="close" onClick={handleCloseDetailsModal}>
                            <CloseIcon color="primary" />
                        </IconButton>
                    </Box>

                    <VisitMatches
                        caseBased={caseBased}
                        selectedIds={multiSelection.selected}
                        selectedIsDirty={selectedIsDirty}
                        setSelectedIsDirty={setSelectedIsDirty}
                        visit={visit}
                        visitDates={visitDates}
                        visits={visits}
                        factorSettingsId={globalFactorPSettingId}
                        setPatientPendingInvitesCount={setPatientPendingInvitesCount}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={showPatientSchedule} onClose={() => handleClosePatientSchedule()}>
                <PatientWeek
                    patientId={visit?.patientId}
                    weekDates={[visitDates[0]?.toDate() || new Date(), visitDates[1]?.toDate() || new Date()]}
                    showPatientSchedule={setShowPatientSchedule}
                />
            </Dialog>
            <Dialog open={showPatientPreference} onClose={() => setShowPatientPreference(false)}>
                <PreferenceModal
                    patientId={visit?.patientId}
                    caregiverId={''}
                />
            </Dialog>
            {/* <Dialog open={openVisits} onClose={() => setOpenVisits(false)}>
                <Box width={'1200px'} height={'600px'}>
                    <Calendar caseVisits={caseVisits} height="80px" module="patient" id={caseVisits[0]?.patientId} />
                </Box>
            </Dialog> */}
            <Dialog open={openCaseVisitsModal && !!visitDates[0]} onClose={(handleCloseCaseVisitsModal)}>
                <Box width={'1250px'} height={'auto'} sx={{ background: '#fff', padding: '10px' }}>
                    <Box sx={{ position: 'absolute', right: 0, top: 0, paddingTop: '20px', paddingRight: '15px', paddingLeft: '10px', }}>
                        {findCaregiversForSelectedShifts()}
                    </Box>
                    {!!patientId && !!visitDates[0]?.toDate() &&
                        <Calendar passedDate={visitDates[0]?.toDate()} module='patient' id={patientId.toString()} showCaregiver showPatient={false}
                            selectMode={true} selectedIds={multiSelection.selected} setSelectedIds={multiSelection.setSelected}
                        />
                    }
                    {/* <DataGrid
                        title={'Case Visits'}
                        data={{
                            columns: caseVisitsCols(),
                            rows: caseVisits || []
                        }}
                        options={{
                            headerHeight: 30,
                            rowHeight: 30,
                            hideExport: true,
                            hideToolbarIconText: true,
                            noRowsOverlayText: 'No data found',
                            hideFiltering: true,
                            hideColumns: true,
                            toolbarComponent: findCaregiversForSelectedShifts()
                        }}
                    /> */}
                </Box>
            </Dialog>
            <NewModal open={openInvitesList && !openInvitesDetails} onClose={() => handleCloseInviteList()} title="Invites" onSave={() => { }} hideBtn height="400px">
                <Box>
                    <Loader isLoading={invitesFetcher.isLoading} />
                    <List disablePadding sx={{ height: '330px', overflow: 'scroll', ...CUSTOM_SCROLL }}>
                        {invites.map((invite: any) => (
                            <ListItemButton onClick={() => handleOpenInviteDetails(invite)} sx={{ border: `1px solid ${app_purple}`, borderRadius: '10px', margin: '10px' }}>
                                <ListItemText
                                    primary={`${invite.inviteCode} - ${invite.caregiverName}`}
                                    secondary={
                                        <div>
                                            <Chip
                                                size="small"
                                                label={invite.status}
                                                sx={{
                                                    width: '100px',
                                                    borderRadius: '5px',
                                                    marginRight: '10px',
                                                    background: 'transparent',
                                                    color: getStatusColor(invite.status),
                                                    border: `1px solid ${getStatusColor(invite.status)}`
                                                }}
                                            />
                                            <Text>Sent: {dateTime.getDisplayValue(invite.sentOn)}</Text>
                                        </div>
                                    }
                                />
                            </ListItemButton>
                        ))}
                    </List>
                </Box>
            </NewModal>
            <InviteDetailsModal open={openInvitesDetails} setOpen={setOpenInvitesDetails} inviteCode={selectedInvite?.inviteCode} />
            <VisitFilter
                open={openFilter}
                setOpen={setOpenFilter}
                search={loadVisits}
                selectedOffices={selectedOffices}
                selectedCoordinators={selectedCoordinators}
                selectedGender={selectedGender}
                selectedType={selectedType}
                address={address}
                setAddress={setAddress}
                setSelectedOffices={setSelectedOffices}
                setSelectedCoordinators={setSelectedCoordinators}
                setSelectedGender={setSelectedGender}
                setSelectedType={setSelectedType}
                offices={offices}
                coordinators={coordinators}
            />

            <FactorSettings
                setGlobalFactorPSettingId={setGlobalFactorPSettingId}
                selectedProfileId={globalFactorPSettingId}
                open={openFactorSettings}
                setOpen={setOpenFactorSettings}
            />
            <PendingInvites
                openPendingInvites={openPending}
                setOpenPendingInvites={setOpenPending}
                visitId={visit?.visitId}
                filterPatientId={visit?.patientId}
                selectedVisitIDs={multiSelection.selected}
            />
            <Modal open={openModal} onClose={() => handleCloseModal()}>
                <Box sx={{
                    top: '20%',
                    left: '20%',
                    width: '60%',
                    backgroundColor: "white",
                    position: "absolute",
                    padding: 4,
                }}>
                    <InviteHistory
                        visitId={inviteRow?.visitId}
                    />
                </Box>
            </Modal>
            <MySnackbar
                type="success"
                message="Watching status updated."
                openSnack={openSnack}
                handleCloseSnack={handleCloseSnack}
            />
        </div>
    );
};

export default VisitsBoard;
